/**
 * @class DFWord allows me to easily create all forms of a word without copying, pasting and formatting every single word form.
 * @constructor takes in four params. Each one should be a string delimited by ":" for each vord of that form. Pass in "" or "" to indicate that there are no words of that form
 * @param noun "word1:word2" if this word type exists, or null/"" if not
 * @param verb "word1:word2" if this word type exists, or null/"" if not
 * @param adj "word1:word2" if this word type exists, or null/"" if not
 * @param prefix "word1:word2" if this word type exists, or null/"" if not
 */
class DFWord {
  constructor(noun, verb, adj, prefix) {
    // establish internal vars. If truthy, split by colon, if not leave an empty array
    this.nouns = noun ? noun.split(":") : [];
    this.verbs = verb ? verb.split(":") : [];
    this.adjs = adj ? adj.split(":") : [];
    this.prefixes = prefix ? prefix.split(":") : [];
    this.hasNounForm = this.nouns.length > 0;
    this.hasVerbForm = this.verbs.length > 0;
    this.hasAdjForm = this.adjs.length > 0;
    this.hasPrefixForm = this.prefixes.length > 0;
  };
  /**
   * @function getNoun
   * @function getVerb
   * @function getAdj
   * @function getPrefix
   * @param form 0 or 1 to indicate singular or plural
   * TODO: make a constant object to translate forms to numbers for the user
   */
  getNoun(form) {
    return (this.nouns.length >= form) ? this.nouns[form] : "noNOUNform";
  }
  getVerb(form) {
    return (this.verbs.length >= form) ? this.verbs[form] : "noVERBform";
  }
  getAdj(form) {
    return (this.adjs.length >= form) ? this.adjs[form] : "noADJform";
  }
  getPrefix(form) {
    return (this.prefixes.length >= form) ? this.prefixes[form] : "noPREFIXform";
  }
  hasWordType(type) {
    if(!["noun", "verb", "adj", "prefix"].includes(type)) {
      throw Error(`Invalid type ${type} passed into DFWord class method hasWordType()`);
    } else if( type === "noun" ) {
      return this.hasNounForm;
    } else if( type === "verb" ) {
      return this.hasVerbForm;
    } else if( type === "adj" ) {
      return this.hasAdjForm;
    } else if( type === "prefix" ) {
      return this.hasPrefixForm;
    }
  }
}

/**
 * Word Types:
 *  Nouns: "singular:plural"
 *  Verbs: "root:3person_sing:past:past_participle:present_participle"
 *  Adjective: adj
 *  Prefix: prefix
 */

export const wordTypes = {
  "": new DFWord(null, null, null, null),
  "abbey": new DFWord("abbey:abbeys", null, null, null),
  "ace": new DFWord("ace:aces", null, "ace", null),
  "act": new DFWord("act:acts", "act:acts:acted:acted:acting", null, null),
  "after": new DFWord(null, null, null, "after"),
  "age": new DFWord("age:ages", "age:ages:aged:aged:aging", null, null),
  "ageless": new DFWord(null, null, "ageless", null),
  "ale": new DFWord("ale:ales", null, null, null),
  "ancient": new DFWord("ancient:ancients", null, "ancient", null),
  "angel": new DFWord("angel:angels", null, "angelic", null),
  "anger": new DFWord("anger:angers", null, "angry", null),
  "animal": new DFWord("animal:animals", null, null, null),
  "ape": new DFWord("ape:apes", null, null, null),
  "apple": new DFWord("apple:apples", null, null, null),
  "arch": new DFWord(null, null, null, "arch"),
  "arm": new DFWord("arm:arms", null, null, null),
  "armor": new DFWord("armor:armors", null, "armored", null),
  "arrow": new DFWord("arrow:arrows", null, null, null),
  "artifice": new DFWord("artifice:artifices", null, "artificial", null),
  "ash": new DFWord("ash:ashes", null, "ashen", null),
  "aunt": new DFWord("aunt:aunts", null, null, null),
  "aura": new DFWord("aura:auras", null, null, null),
  "autumn": new DFWord("autumn:autumns", null, "autumnal", null),
  "awe-inspiring": new DFWord(null, null, "awe-inspiring", null),
  "axe": new DFWord("axe:axes", null, null, null),
  "back": new DFWord("back:backs", null, null, null),
  "bad": new DFWord(null, null, "bad", null),
  "bald": new DFWord("baldness:baldnesses", "bald:balds:balded:balded:balding", "bald", null),
  "bake": new DFWord("baker:bakers", "bake:bakes:baked:baked:baking", null, null),
  "ball": new DFWord("ball:balls", null, null, null),
  "bane": new DFWord("bane:banes", null, null, null),
  "bar": new DFWord("bar:bars", null, null, null),
  "barb": new DFWord("barb:barbs", null, null, null),
  "bath": new DFWord("bath:baths", "bathe:bathes:bathed:bathed:bathing", null, null),
  "battle": new DFWord("battle:battles", null, null, null),
  "beak": new DFWord("beak:beaks", null, null, null),
  "beast": new DFWord("beast:beasts", null, null, null),
  "bear": new DFWord("bear:bears", null, null, null),
  "bear_verb": new DFWord(null, "bear:bears:bore:born:bearing", null, null),
  "bee": new DFWord("bee:bees", null, null, null),
  "beer": new DFWord("beer:beers", null, null, null),
  "beetle": new DFWord("beetle:beetles", null, null, null),
  "beguiler": new DFWord("beguiler:beguilers", "beguile:beguiles:beguiled:beguiled:beguiling", null, null),
  "belch": new DFWord("belch:belches", null, null, null),
  "berry": new DFWord("berry:berries", null, null, null),
  "big": new DFWord(null, null, "big", null),
  "bile": new DFWord("bile:biles", null, null, null),
  "bin": new DFWord("bin:bins", null, null, null),
  "bird": new DFWord("bird:birds", null, null, null),
  "black": new DFWord("blackness:blacknesses", null, "black", null),
  "blade": new DFWord("blade:blades", null, null, null),
  "blaze": new DFWord("blaze:blazes", "blaze:blazes:blazed:blazed:blazing", null, null),
  "blind": new DFWord("blindness:blindnesses", "blind:blinds:blinded:blinded:blinding", "blind", null),
  "blister": new DFWord("blister:blisters", null, null, null),
  "bloat": new DFWord("bloat:bloats", null, "bloated", null),
  "blood": new DFWord("blood:bloods", null, null, null),
  "bloody": new DFWord("bloodiness:bloodinesses", null, "bloody", null),
  "blossom": new DFWord("blossom:blossoms", "blossom:blossoms:blossomed:blossomed:blossoming", null, null),
  "blue": new DFWord("blueness:bluenesses", null, "blue", null),
  "boar": new DFWord("boar:boars", null, null, null),
  "boat": new DFWord("boat:boats", null, null, null),
  "bodice": new DFWord("bodice:bodices", null, null, null),
  "bog": new DFWord("bog:bogs", null, null, null),
  "boil": new DFWord("boil:boils", null, null, null),
  "boil_v": new DFWord(null, "boil:boils:boiled:boiled:boiling", null, null),
  "bold": new DFWord("boldness:boldnesses", null, "bold", null),
  "bolt": new DFWord("bolt:bolts", "bolt:bolts:bolted:bolted:bolting", null, null),
  "bone": new DFWord("bone:bones", null, null, null),
  "book": new DFWord("book:books", null, null, null),
  "boot": new DFWord("boot:boots", null, null, null),
  "bother": new DFWord(null, "bother:bothers:bothered:bothered:bothering", null, null),
  "bow": new DFWord("bow:bows", null, null, null),
  "bow_verb": new DFWord(null, "bow:bows:bowed:bowed:bowing", null, null),
  "brain": new DFWord("brain:brains", null, null, null),
  "breach": new DFWord("breach:breaches", "breach:breaches:breached:breached:breaching", null, null),
  "breeches": new DFWord("breeches:breeches", null, null, null),
  "bread": new DFWord("bread:breads", null, null, null),
  "break": new DFWord("breaker:breakers", "break:breaks:broke:broken:breaking", null, null),
  "breakfast": new DFWord("breakfast:breakfasts", null, null, null),
  "breath": new DFWord("breath:breaths", null, null, null),
  "breed": new DFWord("breed:breeds", null, null, null),
  "brew": new DFWord("brew:brews", null, null, null),
  "bride": new DFWord("bride:brides", null, null, null),
  "bridle": new DFWord("bridle:bridles", null, "bridled", null),
  "bright": new DFWord("brightness:brightnesses", null, "bright", null),
  "bristle": new DFWord("bristle:bristles", "bristle:bristles:bristled:bristled:bristling", null, null),
  "brilliant": new DFWord("brilliance:brilliances", null, "brilliant", null),
  "brim": new DFWord("brim:brims", null, null, null),
  "broil": new DFWord(null, "broil:broils:broiled:broiled:broiling", null, null),
  "brother": new DFWord("brother:brothers", null, null, null),
  "brunch": new DFWord("brunch:brunches", null, null, null),
  "buck": new DFWord("buck:bucks", null, null, null),
  "buckle": new DFWord("buckle:buckles", "buckle:buckles:buckled:buckled:buckling", null, null),
  "bunch": new DFWord("bunch:bunches", null, null, null),
  "burden": new DFWord("burden:burdens", null, null, null),
  "burn": new DFWord("burn:burns", "burn:burns:burned:burned:burning", null, null),
  "bury": new DFWord("burial:burials", "bury:buries:buried:buried:burying", null, null),
  "bush": new DFWord("bush:bushes", null, null, null),
  "bushel": new DFWord("bushel:bushels", null, null, null),
  "bust_noun": new DFWord("bust:busts", null, null, null),
  "bust_verb": new DFWord("buster:busters", "bust:busts:busted:busted:busting", null, null),
  "business": new DFWord("business:businesses", null, null, null),
  "busy": new DFWord("business:business", null, "busy", null),
  "butcher": new DFWord("butcher:butchers", null, null, null),
  "butter": new DFWord("butter:butters", "butter:butters:buttered:buttered:buttering", "buttery", null),
  "butterfly": new DFWord("butterfly:butterflies", null, null, null),
  "button": new DFWord("button:buttons", "button:buttons:buttoned:buttoned:buttoning", null, null),
  "buzzard": new DFWord("buzzard:buzzards", null, null, null),
  "cackle": new DFWord(null, "cackle:cackles:cackled:cackled:cackling", null, null),
  "cad": new DFWord("cad:cads", null, null, null),
  "cage": new DFWord("cage:cages", null, null, null),
  "cake": new DFWord("cake:cakes", null, null, null),
  "call": new DFWord("call:calls", "call:calls:called:called:calling", null, null),
  "camp": new DFWord("camp:camps", "camp:camps:camped:camped:camping", null, null),
  "cancer": new DFWord("cancer:cancers", null, null, null),
  "candle": new DFWord("candle:candles", null, null, null),
  "candy": new DFWord("candy:candies", null, null, null),
  "canker": new DFWord("canker:cankers", null, null, null),
  "canyon": new DFWord("canyon:canyons", null, null, null),
  "carnage": new DFWord("carnage:carnages", null, null, null),
  "casket": new DFWord("casket:caskets", null, null, null),
  "castle": new DFWord("castle:castles", null, null, null),
  "cat": new DFWord("cat:cats", null, null, null),
  "cave": new DFWord("cave:caves", null, null, null),
  "cell": new DFWord("cell:cells", null, null, null),
  "chain": new DFWord("chain:chains", "chain:chains:chained:chained:chaining", null, null),
  "chant": new DFWord("chant:chants", "chant:chants:chanted:chanted:chanting", null, null),
  "chaos": new DFWord("chaos:chaoses", null, null, null),
  "chamber": new DFWord("chamber:chambers", null, null, null),
  "char": new DFWord(null, "char:chars:charred:charred:charring", null, null),
  "charm": new DFWord("charm:charms", "charm:charms:charmed:charmed:charming", null, null),
  "child": new DFWord("child:children", null, null, null),
  "chill": new DFWord("chill:chills", "chill:chills:chilled:chilled:chilling", null, null),
  "chip_noun": new DFWord("chip:chips", null, null, null),
  "chip_verb": new DFWord(null, "chip:chips:chipped:chipped:chipping", null, null),
  "chirp": new DFWord(null, "chirp:chirps:chirped:chirped:chirping", null, null),
  "choke": new DFWord(null, "choke:chokes:choked:choked:choking", null, null),
  "chop": new DFWord(null, "chop:chops:chopped:chopped:chopping", null, null),
  "chuck": new DFWord(null, "chuck:chucks:chucked:chucked:chucking", null, null),
  "cinder": new DFWord("cinder:cinders", null, null, null),
  "clam": new DFWord("clam:clams", null, null, null),
  "clap": new DFWord(null, "clap:claps:clapped:clapped:clapping", null, null),
  "clasp": new DFWord("clasp:clasps", "clasp:clasps:clasped:clasped:clasping", null, null),
  "claw": new DFWord("claw:claws", null, null, null),
  "clear": new DFWord(null, "clear:clears:cleared:cleared:clearing", "clear", null),
  "clearing": new DFWord("clearing:clearings", null, null, null),
  "cleave": new DFWord("cleft:clefts", "cleave:cleaves:clove:cloven:cleaving", null, null),
  "cling": new DFWord(null, "cling:clings:clinged:clinged:clinging", null, null),
  "cloak": new DFWord("cloak:cloaks", null, null, null),
  "clobber": new DFWord(null, "clobber:clobbers:clobbered:clobbered:clobbering", null, null),
  "clock": new DFWord("clock:clocks", null, null, null),
  "clod": new DFWord("clod:clods", null, null, null),
  "cloister": new DFWord("cloister:cloisters", "cloister:cloisters:cloistered:cloistered:cloistering", null, null),
  "closet": new DFWord("closet:closets", null, null, null),
  "club": new DFWord("club:clubs", null, null, null),
  "cluster": new DFWord("cluster:clusters", "cluster:clusters:clustered:clustered:clustering", null, null),
  "clutter": new DFWord("clutter:clutter", "clutter:clutters:cluttered:cluttered:cluttering", null, null),
  "cobra": new DFWord("cobra:cobras", null, null, null),
  "coil": new DFWord("coil:coils", "coil:coils:coiled:coiled:coiling", null, null),
  "cold": new DFWord("coldness:coldnesses", null, "cold", null),
  "color": new DFWord("color:colors", "color:colors:colored:colored:coloring", null, null),
  "comet": new DFWord("comet:comets", null, null, null),
  "conqueror": new DFWord("conqueror:conquerors", null, null, null),
  "cook": new DFWord("cook:cooks", "cook:cooks:cooked:cooked:cooking", null, null),
  "cover": new DFWord("cover:covers", "cover:covers:covered:covered:covering", null, null),
  "cotton": new DFWord("cotton:cottons", null, null, null),
  "couple": new DFWord("couple:couples", null, null, null),
  "crab": new DFWord("crab:crabs", null, null, null),
  "crawl": new DFWord(null, "crawl:crawls:crawled:crawled:crawling", null, null),
  "craze": new DFWord("craze:crazes", null, "crazed", null),
  "crazy": new DFWord("craziness:crazinesses", null, "crazy", null),
  "cream": new DFWord("cream:creams", null, "creamy", null),
  "creek": new DFWord("creek:creeks", null, null, null),
  "creep": new DFWord("creep:creeps", "creep:creeps:crept:crept:creeping", null, null),
  "creepy": new DFWord("creepiness:creepinesses", null, "creepy", null),
  "crescent": new DFWord("crescent:crescents", null, null, null),
  "cross_noun": new DFWord("cross:crosses", null, null, null),
  "cross_verb": new DFWord(null, "cross:crosses:crossed:crossed:crossing", null, null),
  "cross_adj": new DFWord(null, null, "cross", null),
  "crow": new DFWord("crow:crows", null, null, null),
  "crown": new DFWord("crown:crowns", null, null, null),
  "crucify": new DFWord("crucifixion:crucifixions", "crucify:crucifies:crucified:crucified:crucifying", null, null),
  "crumble": new DFWord(null, "crumble:crumbles:crumbled:crumbled:crumbling", null, null),
  "crush": new DFWord(null, "crush:crushes:crushed:crushed:crushing", null, null),
  "crusher": new DFWord("crusher:crushers", null, null, null),
  "cry": new DFWord(null, "cry:cries:cried:cried:crying", null, null),
  "crypt": new DFWord("crypt:crypts", null, null, null),
  "crystal": new DFWord("crystal:crystals", null, "crystalline", null),
  "cuddle": new DFWord(null, "cuddle:cuddles:cuddled:cuddled:cuddling", null, null),
  "cudgel": new DFWord("cudgel:cudgels", null, null, null),
  "cup": new DFWord("cup:cups", null, null, null),
  "curse": new DFWord("curse:curses", null, null, null),
  "cut": new DFWord("cut:cuts", null, null, null),
  "cyst": new DFWord("cyst:cysts", null, null, null),
  "dance": new DFWord("dance:dances", "dance:dances:danced:danced:dancing", null, null),
  "dangle": new DFWord(null, "dangle:dangles:dangled:dangled:dangling", null, null),
  "dank": new DFWord("dankness:danknesses", null, "dank", null),
  "dagger": new DFWord("dagger:daggers", null, null, null),
  "dark": new DFWord("darkness:darknesses", null, "dark", null),
  "date_fruit": new DFWord("date:dates", null, null, null),
  "date_verb": new DFWord("date:dates", "date:dates:dated:dated:dating", null, null),
  "dawn": new DFWord("dawn:dawns", "dawn:dawns:dawned:dawned:dawning", null, null),
  "day": new DFWord("day:days", null, null, null),
  "dead": new DFWord("dead:dead", null, "dead", null),
  "dear": new DFWord(null, null, "dear", null),
  "death": new DFWord("death:deaths", "die:dies:died:died:dying", null, null),
  "decay": new DFWord("decay:decays", null, null, null),
  "deceiver": new DFWord("deceiver:deceivers", null, null, null),
  "deep": new DFWord("deep:deeps", null, "deep", null),
  "deer": new DFWord("deer:deer", null, null, null),
  "demon": new DFWord("demon:demons", null, null, null),
  "despair": new DFWord("despair:despairs", null, null, null),
  "destroyer": new DFWord("destroyer:destroyers", null, null, null),
  "destruction": new DFWord("destruction:destructions", null, null, null),
  "devil": new DFWord("devil:devils", null, null, null),
  "devourer": new DFWord("devourer:devourers", null, null, null),
  "dine": new DFWord(null, "dine:dines:dined:dined:dining", null, null),
  "dinner": new DFWord("dinner:dinners", null, null, null),
  "dirt": new DFWord("dirt:dirts", null, null, null),
  "ditch": new DFWord("ditch:ditches", null, null, null),
  "dog": new DFWord("dog:dogs", null, null, null),
  "domestic": new DFWord("domicile:domiciles", null, "domestic", null),
  "donkey": new DFWord("donkey:donkeys", null, null, null),
  "doom": new DFWord("doom:dooms", null, "doomed", null),
  "door": new DFWord("door:doors", null, null, null),
  "dragon": new DFWord("dragon:dragons", null, null, null),
  "drain": new DFWord("drain:drains", "drain:drains:drained:drained:draining", null, null),
  "drawl": new DFWord("drawl:drawls", "drawl:drawls:drawled:drawled:drawling", null, null),
  "dreg": new DFWord("dreg:dregs", null, null, null),
  "dress_clothing": new DFWord("dress:dresses", null, null, null),
  "dress_general": new DFWord("dress:dresses", "dress:dresses:dressed:dressed:dressing", null, null),
  "drinker": new DFWord("drinker:drinkers", null, null, null),
  "drip": new DFWord("drip:drips", "drip:drips:dripped:dripped:dripping", null, null),
  "drool": new DFWord("drool:drools", null, null, null),
  "drowned": new DFWord(null, "drown:drowns:drowned:drowned:drowning", null, null),
  "drum": new DFWord("drum:drums", null, null, null),
  "dry": new DFWord(null, "dry:dries:dried:dried:drying", null, null),
  "dump": new DFWord("dump:dumps", "dump:dumps:dumped:dumped:dumping", null, null),
  "dumpling": new DFWord("dumpling:dumplings", null, null, null),
  "dung": new DFWord("dung:dungs", null, null, null),
  "dungeon": new DFWord("dungeon:dungeons", null, null, null),
  "dusk": new DFWord("dusk:dusks", null, null, null),
  "dust": new DFWord("dust:dusts", null, null, null),
  "duty": new DFWord("duty:duties", null, "dutiful", null),
  "dye": new DFWord("dye:dyes", null, null, null),
  "eagle": new DFWord("eagle:eagles", null, null, null),
  "ear": new DFWord("ear:ears", null, null, null),
  "east": new DFWord(null, null, "east", null),
  "eat": new DFWord("eater:eaters", "eat:eats:ate:eaten:eating", null, null),
  "ecto": new DFWord(null, null, null, "ecto"),
  "eel": new DFWord("eel:eels", null, null, null),
  "eerie": new DFWord("eerieness:eerienesses", null, "eerie", null),
  "egg": new DFWord("egg:eggs", null, null, null),
  "elder": new DFWord("elder:elders", null, "elder", null),
  "entrails": new DFWord("entrails:entrails", null, null, null),
  "eternal": new DFWord(null, null, "eternal", null),
  "ever": new DFWord(null, null, null, "ever"),
  "everlasting": new DFWord(null, null, "everlasting", null),
  "everseeing": new DFWord(null, null, "everseeing", null),
  "evil": new DFWord("evil:evils", null, "evil", null),
  "execute": new DFWord("execution:executions", "execute:executes:executed:executed:executing", null, null),
  "executioner": new DFWord("executioner:executioners", null, null, null),
  "eye": new DFWord("eye:eyes", null, null, null),
  "face": new DFWord("face:faces", null, null, null),
  "fall": new DFWord("fall:falls", "fall:falls:fell:fallen:falling", null, null),
  "false": new DFWord("falsehood:falsehoods", null, "false", null),
  "famine": new DFWord("famine:famines", null, null, null),
  "fang": new DFWord("fang:fangs", null, null, null),
  "fat": new DFWord("fat:fats", null, "fat", null),
  "fatal": new DFWord(null, null, "fatal", null),
  "father": new DFWord("father:fathers", null, null, null),
  "fear": new DFWord("fear:fears", null, null, null),
  "feast": new DFWord("feast:feasts", null, null, null),
  "fetid": new DFWord(null, null, "fetid", null),
  "fiend": new DFWord("fiend:fiends", null, null, null),
  "fierce": new DFWord("fierceness:fiercenesses", null, "fierce", null),
  "filth": new DFWord("filth:filths", null, null, null),
  "fin": new DFWord("fin:fins", null, null, null),
  "find": new DFWord("finder:finders", "find:finds:found:found:finding", null, null),
  "finger": new DFWord("finger:fingers", null, null, null),
  "fire": new DFWord("fire:fires", null, "fiery", null),
  "first": new DFWord(null, null, "first", null),
  "fish_animal": new DFWord("fish:fishes", null, null, null),
  "fish_verb": new DFWord("fisher:fishers", "fish:fishes:fished:fished:fishing", null, null),
  "fist": new DFWord("fist:fists", null, null, null),
  "flame": new DFWord("flame:flames", null, null, null),
  "flank": new DFWord("flank:flanks", null, null, null),
  "flea": new DFWord("flea:fleas", null, null, null),
  "flesh": new DFWord("flesh:fleshes", null, "fleshy", null),
  "fling": new DFWord(null, "fling:flings:flung:flung:flinging", null, null),
  "flood": new DFWord("flood:floods", "flood:floods:flooded:flooded:flooding", null, null),
  "flower": new DFWord("flower:flowers", null, "flowery", null),
  "flute": new DFWord("flute:flutes", null, null, null),
  "fly_animal": new DFWord("fly:flies", null, null, null),
  "fly_verb": new DFWord("flier:fliers", "fly:flies:flew:flew:flying", null, null),
  "focus": new DFWord("focus:focuses", "focus:focuses:focused:focused:focusing", null, null),
  "fog": new DFWord("fog:fogs", null, "foggy", null),
  "fool": new DFWord("fool:fools", null, "foolish", null),
  "foot": new DFWord("foot:feet", null, null, null),
  "forest": new DFWord("forest:forests", null, null, null),
  "fork": new DFWord("fork:forks", "fork:forks:forked:forked:forking", null, null),
  "fortify": new DFWord("fortification:fortifications", "fortify:fortifies:fortified:fortified:fortifying", null, null),
  "fortress": new DFWord("fortress:fortresses", null, null, null),
  "fragile": new DFWord(null, null, "fragile", null),
  "fragment": new DFWord("fragment:fragments", "fragment:fragments:fragmented:fragmented:fragmenting", null, null),
  "frame": new DFWord("frame:frames", "frame:frames:framed:framed:framing", null, null),
  "fray": new DFWord(null, "fray:frays:frayed:frayed:fraying", null, null),
  "free": new DFWord("freedom:freedoms", null, "free", null),
  "frenzy": new DFWord("frenzy:frenzies", null, null, null),
  "freeze": new DFWord(null, "freeze:freezes:froze:frozen:freezing", null, null),
  "frill": new DFWord("frill:frills", null, "frilly", null),
  "frog": new DFWord("frog:frogs", null, null, null),
  "fruit": new DFWord("fruit:fruits", null, "fruity", null),
  "fungus": new DFWord("fungus:fungi", null, null, null),
  "fur": new DFWord("fur:furs", null, null, null),
  "gall": new DFWord("gall:galls", null, null, null),
  "game": new DFWord("game:games", null, null, null),
  "garish": new DFWord(null, null, "garish", null),
  "garlic": new DFWord("garlic:garlics", null, null, null),
  "garnish": new DFWord("garnish:garnishes", "garnish:garnishes:garnished:garnished:garnishing", null, null),
  "gate": new DFWord("gate:gates", null, null, null),
  "gaunt": new DFWord(null, null, "gaunt", null),
  "gaze": new DFWord("gaze:gazes", null, null, null),
  "gear": new DFWord("gear:gears", null, "geared", null),
  "gerbil": new DFWord("gerbil:gerbils", null, null, null),
  "ghost": new DFWord("ghost:ghosts", null, null, null),
  "ghoul": new DFWord("ghoul:ghouls", null, null, null),
  "gift": new DFWord("gift:gifts", "gift:gifts:gifted:gifted:gifting", null, null),
  "gill": new DFWord("gill:gills", null, null, null),
  "girdle": new DFWord("girdle:girdles", null, "girdled", null),
  "gland": new DFWord("gland:glands", null, null, null),
  "glaze": new DFWord("glaze:glazes", "glaze:glazes:glazed:glazed:glazing", null, null),
  "glen": new DFWord("glen:glens", null, null, null),
  "glide": new DFWord(null, "glide:glides:glided:glided:gliding", null, null),
  "glimmer": new DFWord("glimmer:glimmers", "glimmer:glimmers:glimmered:glimmered:glimmering", null, null),
  "glisten": new DFWord(null, "glisten:glistens:glistened:glistened:glistening", null, null),
  "gloss": new DFWord("gloss:glosses", "gloss:glosses:glossed:glossed:glossing", null, null),
  "glove": new DFWord("glove:gloves", null, "gloved", null),
  "glutton": new DFWord("glutton:gluttons", null, null, null),
  "goat": new DFWord("goat:goats", null, null, null),
  "god": new DFWord("god:gods", null, "godly", null),
  "god-forsaken": new DFWord(null, null, "god", null),
  "gold": new DFWord("gold:golds", null, "golden", null),
  "goose": new DFWord("goose:geese", null, null, null),
  "gore": new DFWord(null, "gore:gores:gored:gored:goring", null, null),
  "gorge_noun": new DFWord("gorge:gorges", null, null, null),
  "gorge_verb": new DFWord(null, "gorge:gorges:gorged:gorged:gorging", null, null),
  "grain": new DFWord("grain:grains", null, null, null),
  "grand": new DFWord(null, null, "grand", null),
  "grape": new DFWord("grape:grapes", null, null, null),
  "grass": new DFWord("grass:grasses", null, null, null),
  "grave": new DFWord("grave:graves", null, null, null),
  "grease": new DFWord("grease:greases", null, null, null),
  "great": new DFWord("greatness:greatnesses", null, "great", null),
  "greed": new DFWord("greed:greeds", null, null, null),
  "green": new DFWord("greenness:greennessses", null, "green", null),
  "griffon": new DFWord("griffon:griffons", null, null, null),
  "grill": new DFWord(null, "grill:grills:grilled:grilled:grilling", null, null),
  "grim": new DFWord(null, null, "grim", null),
  "grind": new DFWord(null, "grind:grinds:ground:ground:grinding", null, null),
  "grip": new DFWord("grip:grips", "grip:grips:gripped:gripped:gripping", null, null),
  "gristle": new DFWord("gristle:gristles", null, "gristly", null),
  "grizzle": new DFWord("grizzle:grizzles", null, "grizzly", null),
  "grove": new DFWord("grove:groves", null, null, null),
  "grow": new DFWord("grower:growers", "grow:grows:grew:grown:growing", null, null),
  "growl": new DFWord("growl:growls", "growl:growls:growled:growled:growling", null, null),
  "growth": new DFWord("growth:growths", null, null, null),
  "grub": new DFWord("grub:grubs", null, null, null),
  "guard": new DFWord("guard:guards", "guard:guards:guarded:guarded:guarding", null, null),
  "guile": new DFWord("guile:guiles", null, null, null),
  "guise": new DFWord("guise:guises", null, null, null),
  "gut": new DFWord("gut:guts", null, null, null),
  "gutter": new DFWord("gutter:gutters", null, null, null),
  "hag": new DFWord("hag:hags", null, null, null),
  "hair": new DFWord("hair:hairs", null, null, null),
  "hairy": new DFWord(null, null, "hairy", null),
  "hame": new DFWord("hame:hames", null, null, null),
  "hammer": new DFWord("hammer:hammers", null, null, null),
  "hammerer": new DFWord("hammerer:hammerers", null, null, null),
  "hand": new DFWord("hand:hands", null, null, null),
  "handy": new DFWord(null, null, "handy", null),
  "hare": new DFWord("hare:hares", null, null, null),
  "harvest": new DFWord("harvest:harvests", null, null, null),
  "harvest_verb": new DFWord("harvester:harvesters", "harvest:harvests:harvested:harvested:harvesting", null, null),
  "hatchet": new DFWord("hatchet:hatchets", null, null, null),
  "hate": new DFWord("hate:hates", "hate:hates:hated:hated:hating", "hateful", null),
  "haunt": new DFWord("haunt:haunts", "haunt:haunts:haunted:haunted:haunting", null, null),
  "hawk": new DFWord("hawk:hawks", null, null, null),
  "hay": new DFWord("hay:hays", null, null, null),
  "head": new DFWord("head:heads", null, null, null),
  "heart": new DFWord("heart:hearts", null, null, null),
  "hearth": new DFWord("hearth:hearths", null, null, null),
  "heather": new DFWord("heather:heathers", null, null, null),
  "hedge": new DFWord("hedge:hedges", null, null, null),
  "hell": new DFWord("hell:hells", null, "hellish", null),
  "helm": new DFWord("helm:helms", null, "helmed", null),
  "hero": new DFWord("hero:heroes", null, "heroic", null),
  "hex": new DFWord("hex:hexes", null, null, null),
  "hide": new DFWord("hide:hides", null, null, null),
  "hideous": new DFWord(null, null, "hideous", null),
  "high": new DFWord(null, null, "high", null),
  "hill": new DFWord("hill:hills", null, null, null),
  "hip": new DFWord("hip:hips", null, null, null),
  "hoary": new DFWord(null, null, "hoary", null),
  "hobble": new DFWord(null, "hobble:hobbles:hobbled:hobbled:hobbling", null, null),
  "hog": new DFWord("hog:hogs", null, null, null),
  "holy": new DFWord("holiness:holinesses", null, "holy", null),
  "hoist": new DFWord(null, "hoist:hoists:hoisted:hoisted:hoisting", null, null),
  "hole": new DFWord("hole:holes", null, null, null),
  "home": new DFWord("home:homes", null, null, null),
  "honey": new DFWord("honey:honeys", null, null, null),
  "hood": new DFWord("hood:hoods", null, null, null),
  "hoof": new DFWord("hoof:hooves", null, null, null),
  "hop_noun": new DFWord("hop:hops", null, null, null),
  "hop_verb": new DFWord("hopper:hoppers", "hop:hops:hopped:hopped:hopping", null, null),
  "horn": new DFWord("horn:horns", null, null, null),
  "horse": new DFWord("horse:horses", null, null, null),
  "hound": new DFWord("hound:hounds", null, null, null),
  "howl": new DFWord("howl:howls", null, null, null),
  "hug": new DFWord("hug:hugs", "hug:hugs:hugged:hugged:hugging", null, null),
  "hum": new DFWord(null, "hum:hums:hummed:hummed:humming", null, null),
  "hunger": new DFWord("hunger:hungers", null, "hungry", null),
  "hustle": new DFWord(null, "hustle:hustles:hustled:hustled:hustling", null, null),
  "ice": new DFWord("ice:ices", null, "icy", null),
  "idle": new DFWord("idleness:idlenesses", null, "idle", null),
  "idol": new DFWord("idol:idols", null, null, null),
  "ill": new DFWord("illness:illnesses", null, "ill", null),
  "impure": new DFWord("impurity:impurities", null, "impure", null),
  "incense": new DFWord("incense:incenses", null, null, null),
  "inch": new DFWord("inch:inches", "inch:inches:inched:inched:inching", null, null),
  "ink": new DFWord("ink:inks", "ink:inks:inked:inked:inking", "inky", null),
  "inn": new DFWord("inn:inns", null, null, null),
  "insanity": new DFWord("insanity:insanities", null, null, null),
  "invisible": new DFWord(null, null, "invisible", null),
  "iron": new DFWord("iron:irons", null, null, null),
  "itch": new DFWord("itch:itches", null, null, null),
  "ivy": new DFWord("ivy:ivy", null, null, null),
  "jack": new DFWord("jack:jacks", null, null, null),
  "jackal": new DFWord("jackal:jackals", null, null, null),
  "jaundice": new DFWord("jaundice:jaundices", null, "jaundiced", null),
  "jaw": new DFWord("jaw:jaws", null, null, null),
  "jest": new DFWord("jester:jesters", "jest:jests:jested:jested:jesting", null, null),
  "joke": new DFWord("joke:jokes", "joke:jokes:joked:joked:joking", null, null),
  "juggle": new DFWord("juggler:jugglers", "juggle:juggles:juggled:juggled:juggling", null, null),
  "juice": new DFWord("juice:juices", null, null, null),
  "jungle": new DFWord("jungle:jungles", null, null, null),
  "just": new DFWord("justice:justices", null, "just", null),
  "justify": new DFWord(null, "justify:justifies:justified:justified:justifying", null, null),
  "keeper": new DFWord("keeper:keepers", null, null, null),
  "keg": new DFWord("keg:kegs", null, null, null),
  "key": new DFWord("key:keys", null, null, null),
  "kindness": new DFWord("kindness:kindnesses", null, "kind", null),
  "killer": new DFWord("killer:killers", "kill:kills:killed:killed:killing", null, null),
  "kiss": new DFWord("kiss:kisses", "kiss:kisses:kissed:kissed:kissing", null, null),
  "king": new DFWord("king:kings", null, null, null),
  "knead": new DFWord(null, "knead:kneads:kneaded:kneaded:kneading", null, null),
  "knife": new DFWord("knife:knives", null, null, null),
  "knight": new DFWord("knight:knights", "knight:knights:knighted:knighted:knighting", null, null),
  "knit": new DFWord(null, "knit:knits:knit:knitted:knitting", null, null),
  "knot": new DFWord("knot:knots", null, null, null),
  "lace": new DFWord("lace:laces", null, "lacy", null),
  "lake": new DFWord("lake:lakes", null, null, null),
  "lard": new DFWord("lard:lards", null, "lardy", null),
  "lark": new DFWord("lark:larks", null, null, null),
  "larva": new DFWord("larva:larvae", null, "larval", null),
  "last": new DFWord(null, null, "last", null),
  "law": new DFWord("law:laws", null, null, null),
  "lazy": new DFWord("laziness:lazinesses", null, "lazy", null),
  "leader": new DFWord("leader:leaders", "lead:leads:led:led:leading", null, null),
  "leaf": new DFWord("leaf:leaves", null, "leafy", null),
  "leak": new DFWord("leak:leaks", "leak:leaks:leaked:leaked:leaking", "leaky", null),
  "lean": new DFWord(null, null, "lean", null),
  "leech": new DFWord("leech:leeches", null, null, null),
  "legend": new DFWord("legend:legends", null, "legendary", null),
  "lens": new DFWord("lens:lenses", null, null, null),
  "leopard": new DFWord("leopard:leopards", null, null, null),
  "leper": new DFWord("leper:lepers", null, null, null),
  "leprosy": new DFWord("leprosy:leprosies", null, null, null),
  "letter": new DFWord("letter:letters", null, null, null),
  "lie": new DFWord("lie:lies", null, null, null),
  "light": new DFWord("light:lights", null, "light", null),
  "lightning": new DFWord("lightning:lightnings", null, null, null),
  "limb": new DFWord("limb:limbs", null, null, null),
  "lip": new DFWord("lip:lips", null, null, null),
  "listen": new DFWord(null, "listen:listens:listened:listened:listening", null, null),
  "lizard": new DFWord("lizard:lizards", null, null, null),
  "lobster": new DFWord("lobster:lobsters", null, null, null),
  "lock": new DFWord("lock:locks", "lock:locks:locked:locked:locking", null, null),
  "lone": new DFWord(null, null, "lone", null),
  "long": new DFWord("length:lengths", null, "long", null),
  "loot": new DFWord("loot:loots", null, null, null),
  "lord": new DFWord("lord:lords", null, null, null),
  "lost": new DFWord(null, null, "lost", null),
  "louse": new DFWord("louse:lice", null, null, null),
  "love": new DFWord("love:loves", "love:loves:loved:loved:loving", "lovely", null),
  "low": new DFWord("lowness:lownesses", null, "low", null),
  "lunch": new DFWord("lunch:lunches", null, null, null),
  "lung": new DFWord("lung:lungs", null, null, null),
  "lurid": new DFWord(null, null, "lurid", null),
  "lurch": new DFWord(null, "lurch:lurches:lurched:lurched:lurching", null, null),
  "lurk": new DFWord(null, "lurk:lurks:lurked:lurked:lurking", null, null),
  "lush": new DFWord(null, null, "lush", null),
  "lust": new DFWord("lust:lusts", null, "lustful", null),
  "lute": new DFWord("lute:lutes", null, null, null),
  "lyric": new DFWord("lyric:lyrics", null, "lyrical", null),
  "maggot": new DFWord("maggot:maggots", null, null, null),
  "magic": new DFWord("magic:magics", null, "magical", null),
  "man": new DFWord("man:men", null, null, null),
  "mange": new DFWord("mange:manges", null, "mangy", null),
  "many": new DFWord(null, null, null, "many"),
  "marsh": new DFWord("marsh:marshes", null, null, null),
  "martyr": new DFWord("martyr:martyrs", "martyr:martyrs:martyred:martyred:martyring", null, null),
  "master": new DFWord("master:masters", "master:masters:mastered:mastered:mastering", null, null),
  "maw": new DFWord("maw:maws", null, null, null),
  "mead": new DFWord("mead:meads", null, null, null),
  "meadow": new DFWord("meadow:meadows", null, null, null),
  "meal_dom": new DFWord("meal:meals", null, null, null),
  "meal_ground": new DFWord("meal:meals", null, null, null),
  "meat": new DFWord("meat:meats", null, null, null),
  "meditation": new DFWord("meditation:meditations", "meditate:meditates:meditated:meditated:meditating", null, null),
  "mellow": new DFWord(null, null, "mellow", null),
  "menace": new DFWord("menace:menaces", "menace:menaces:menaced:menaced:menacing", null, null),
  "mess": new DFWord("mess:messes", null, null, null),
  "metal": new DFWord("metal:metals", null, null, null),
  "midnight": new DFWord("midnight:midnights", null, null, null),
  "mighty": new DFWord("might:mights", null, "mighty", null),
  "mightiness": new DFWord("mightiness:mightinesses", null, null, null),
  "mile": new DFWord("mile:miles", null, null, null),
  "mince": new DFWord(null, "mince:minces:minced:minced:mincing", null, null),
  "mind": new DFWord("mind:minds", null, "mindful", null),
  "minion": new DFWord("minion:minions", null, null, null),
  "mirror": new DFWord("mirror:mirrors", "mirror:mirrors:mirrored:mirrored:mirroring", null, null),
  "misery": new DFWord("misery:miseries", null, null, null),
  "mist": new DFWord("mist:mists", null, "misty", null),
  "mite": new DFWord("mite:mites", null, null, null),
  "mold": new DFWord("mold:molds", null, null, null),
  "mole": new DFWord("mole:moles", null, null, null),
  "molten": new DFWord(null, null, "molten", null),
  "monkey": new DFWord("monkey:monkeys", null, null, null),
  "moon": new DFWord("moon:moons", null, null, null),
  "monger": new DFWord("monger:mongers", null, null, null),
  "moth": new DFWord("moth:moths", null, null, null),
  "mother": new DFWord("mother:mothers", null, null, null),
  "mouse": new DFWord("mouse:mice", null, "mousy", null),
  "mouth": new DFWord("mouth:mouths", null, null, null),
  "muck": new DFWord("muck:mucks", null, null, null),
  "mud": new DFWord("mud:muds", null, "muddy", null),
  "muffin": new DFWord("muffin:muffins", null, null, null),
  "mule": new DFWord("mule:mules", null, null, null),
  "munch": new DFWord(null, "munch:munches:munched:munched:munching", null, null),
  "murder": new DFWord("murder:murders", "murder:murders:murdered:murdered:murdering", null, null),
  "mush": new DFWord("mush:mushes", null, null, null),
  "mushroom": new DFWord("mushroom:mushrooms", null, null, null),
  "music": new DFWord("music:musics", null, "musical", null),
  "mystery": new DFWord("mystery:mysteries", null, "mysterious", null),
  "myth": new DFWord("myth:myths", null, "mythical", null),
  "nail": new DFWord("nail:nails", null, null, null),
  "nasty": new DFWord(null, null, "nasty", null),
  "nature": new DFWord("nature:natures", null, "natural", null),
  "necro": new DFWord(null, null, null, "necro"),
  "negative": new DFWord(null, null, "negative", null),
  "negate": new DFWord("negator:negators", "negate:negates:negated:negated:negating", null, null),
  "neo": new DFWord(null, null, null, "neo"),
  "nest": new DFWord("nest:nests", "nest:nests:nested:nested:nesting", null, null),
  "nestle": new DFWord(null, "nestle:nestles:nestled:nestled:nestling", null, null),
  "net": new DFWord("net:nets", null, null, null),
  "nettle": new DFWord("nettle:nettles", null, null, null),
  "new": new DFWord(null, null, "new", null),
  "newt": new DFWord("newt:newts", null, null, null),
  "nibble": new DFWord(null, "nibble:nibbles:nibbled:nibbled:nibbling", null, null),
  "night": new DFWord("night:nights", null, null, null),
  "nightmare": new DFWord("nightmare:nightmares", null, null, null),
  "noble": new DFWord("noble:nobles", null, "noble", null),
  "noose": new DFWord("noose:nooses", null, null, null),
  "north": new DFWord(null, null, "north", null),
  "nose": new DFWord("nose:noses", null, null, null),
  "null": new DFWord(null, null, "null", null),
  "number": new DFWord("number:numbers", null, null, null),
  "nurture": new DFWord(null, "nurture:nurtures:nurtured:nurtured:nurturing", null, null),
  "nut": new DFWord("nut:nuts", null, null, null),
  "oak": new DFWord("oak:oaks", null, "oaken", null),
  "oar": new DFWord("oar:oars", null, null, null),
  "oat": new DFWord("oat:oats", null, null, null),
  "oblivion": new DFWord("oblivion:oblivions", null, null, null),
  "ocean": new DFWord("ocean:oceans", null, "oceanic", null),
  "old": new DFWord(null, null, "old", null),
  "omen": new DFWord("omen:omens", null, null, null),
  "one": new DFWord("one:ones", null, null, null),
  "one_pref": new DFWord(null, null, null, "one"),
  "onion": new DFWord("onion:onions", null, null, null),
  "ooze": new DFWord("ooze:oozes", null, null, null),
  "open": new DFWord(null, "open:opens:opened:opened:opening", null, null),
  "orb": new DFWord("orb:orbs", null, null, null),
  "over": new DFWord(null, null, null, "over"),
  "owl": new DFWord("owl:owls", null, null, null),
  "ox": new DFWord("ox:oxen", null, null, null),
  "pack": new DFWord("pack:packs", "pack:packs:packed:packed:packing", null, null),
  "pad_noun": new DFWord("pad:pads", null, "padded", null),
  "pad_verb": new DFWord(null, "pad:pads:padded:padded:padding", null, null),
  "page": new DFWord("page:pages", "page:pages:paged:paged:paging", null, null),
  "pain": new DFWord("pain:pains", null, "painful", null),
  "paint": new DFWord("paint:paints", "paint:paints:painted:painted:painting", null, null),
  "pale": new DFWord("paleness:palenesses", null, "pale", null),
  "palm": new DFWord("palm:palms", null, null, null),
  "pant": new DFWord(null, "pant:pants:panted:panted:panting", null, null),
  "panther": new DFWord("panther:panthers", null, null, null),
  "pants": new DFWord("pants:pants", null, null, null),
  "paper": new DFWord("paper:papers", null, null, null),
  "peace": new DFWord("peace:peaces", null, "peaceful", null),
  "peach": new DFWord("peach:peaches", null, null, null),
  "pepper": new DFWord("pepper:peppers", null, null, null),
  "phlegm": new DFWord("phlegm:phlegms", null, null, null),
  "pig": new DFWord("pig:pigs", null, null, null),
  "pit": new DFWord("pit:pits", null, null, null),
  "pitch": new DFWord("pitch:pitches", null, null, null),
  "plague": new DFWord("plague:plagues", null, null, null),
  "plant": new DFWord("plant:plants", "plant:plants:planted:planted:planting", null, null),
  "planter": new DFWord("planter:planters", null, null, null),
  "plot": new DFWord("plot:plots", "plot:plots:plotted:plotted:plotting", null, null),
  "pocket_noun": new DFWord("pocket:pockets", null, null, null),
  "pocket_verb": new DFWord(null, "pocket:pockets:pocketed:pocketed:pocketing", null, null),
  "poison": new DFWord("poison:poisons", null, "poisoned", null),
  "portal": new DFWord("portal:portals", null, null, null),
  "power": new DFWord("power:powers", null, null, null),
  "prank": new DFWord("prank:pranks", null, null, null),
  "pregnant": new DFWord("pregnancy:pregnancies", null, "pregnant", null),
  "pride": new DFWord("pride:prides", null, null, null),
  "priest": new DFWord("priest:priests", null, null, null),
  "prim": new DFWord(null, null, "prim", null),
  "prime": new DFWord(null, null, "prime", null),
  "primitive": new DFWord(null, null, "primitive", null),
  "profane": new DFWord("profanity:profanities", null, "profane", null),
  "prophecy": new DFWord("prophecy:prophecies", null, null, null),
  "prophet": new DFWord("prophet:prophets", null, null, null),
  "prowl": new DFWord("prowler:prowlers", "prowl:prowls:prowled:prowled:prowling", null, null),
  "puke": new DFWord("puke:pukes", null, null, null),
  "pull": new DFWord(null, "pull:pulls:pulled:pulled:pulling", null, null),
  "pulley": new DFWord("pulley:pulleys", null, null, null),
  "pulp": new DFWord("pulp:pulps", null, "pulpy", null),
  "punch": new DFWord("punch:punches", null, null, null),
  "purple": new DFWord(null, null, "purple", null),
  "pus": new DFWord("pus:pusses", null, null, null),
  "putrid": new DFWord("putrescence:putrescences", null, "putrid", null),
  "quake": new DFWord("quake:quakes", null, null, null),
  "queen": new DFWord("queen:queens", null, null, null),
  "quick": new DFWord("quickness:quicknesses", null, "quick", null),
  "quill": new DFWord("quill:quills", null, null, null),
  "rabbit": new DFWord("rabbit:rabbits", null, null, null),
  "rabble": new DFWord("rabble:rabbles", null, null, null),
  "race_group": new DFWord("race:races", null, null, null),
  "race_verb": new DFWord("race:races", "race:races:raced:raced:racing", null, null),
  "rack": new DFWord("rack:racks", null, null, null),
  "rag": new DFWord("rag:rags", null, null, null),
  "rage": new DFWord("rage:rages", null, null, null),
  "rain": new DFWord("rain:rains", "rain:rains:rained:rained:raining", "rainy", null),
  "rake": new DFWord(null, "rake:rakes:raked:raked:raking", null, null),
  "ram": new DFWord("ram:rams", null, null, null),
  "rampage": new DFWord("rampage:rampages", "rampage:rampages:rampaged:rampaged:rampaging", null, null),
  "random": new DFWord(null, null, "random", null),
  "ransack": new DFWord(null, "ransack:ransacks:ransacked:ransacked:ransacking", null, null),
  "raptor": new DFWord("raptor:raptors", null, null, null),
  "rat": new DFWord("rat:rats", null, null, null),
  "raunch": new DFWord("raunch:raunches", null, null, null),
  "ravager": new DFWord("ravager:ravagers", null, null, null),
  "raven": new DFWord("raven:ravens", null, null, null),
  "raw": new DFWord("rawness:rawnesses", null, "raw", null),
  "razor": new DFWord("razor:razors", null, null, null),
  "red": new DFWord("redness:rednesses", null, "red", null),
  "regal": new DFWord(null, null, "regal", null),
  "reign": new DFWord("reign:reigns", "reign:reigns:reigned:reigned:reigning", null, null),
  "rein": new DFWord("rein:reins", "rein:reins:reined:reined:reining", null, null),
  "rend": new DFWord(null, "rend:rends:rent:rent:rending", null, null),
  "rider": new DFWord("rider:riders", null, null, null),
  "rim": new DFWord("rim:rims", null, null, null),
  "ring_object": new DFWord("ring:rings", "ring:rings:ringed:ringed:ringing", null, null),
  "ring_sound": new DFWord(null, "ring:rings:rang:rung:ringing", null, null),
  "rip": new DFWord("rip:rips", null, null, null),
  "ripe": new DFWord("ripeness:ripenesses", null, "ripe", null),
  "ripper": new DFWord("ripper:rippers", null, null, null),
  "rise": new DFWord(null, "rise:rises:rose:risen:rising", null, null),
  "rite": new DFWord("rite:rites", null, null, null),
  "river": new DFWord("river:rivers", null, null, null),
  "rock": new DFWord("rock:rocks", null, null, null),
  "rogue": new DFWord("rogue:rogues", null, null, null),
  "romance": new DFWord("romance:romances", "romance:romances:romanced:romanced:romancing", "romantic", null),
  "room": new DFWord("room:rooms", null, null, null),
  "roomy": new DFWord(null, null, "roomy", null),
  "root": new DFWord("root:roots", null, null, null),
  "rose": new DFWord("rose:roses", null, "rosy", null),
  "rot": new DFWord("rot:rots", null, null, null),
  "rough": new DFWord("roughness:roughnesses", null, "rough", null),
  "royal": new DFWord(null, null, "royal", null),
  "ruin": new DFWord("ruin:ruins", null, "ruined", null),
  "ruin_v": new DFWord(null, "ruin:ruins:ruined:ruined:ruining", null, null),
  "ruler": new DFWord("ruler:rulers", "rule:rules:ruled:ruled:ruling", null, null),
  "rumor": new DFWord("rumor:rumors", null, "rumored", null),
  "rust": new DFWord("rust:rusts", null, null, null),
  "sabre": new DFWord("sabre:sabres", null, null, null),
  "sack": new DFWord("sack:sacks", null, null, null),
  "saint": new DFWord("saint:saints", null, null, null),
  "salt": new DFWord("salt:salts", null, "salty", null),
  "salve": new DFWord("salve:salves", null, null, null),
  "sap": new DFWord("sap:saps", null, null, null),
  "satin": new DFWord("satin:satins", null, "satiny", null),
  "savage": new DFWord("savage:savages", null, "savage", null),
  "savagery": new DFWord("savagery:savageries", null, null, null),
  "savior": new DFWord("savior:saviors", null, null, null),
  "scab": new DFWord("scab:scabs", null, "scabrous", null),
  "scar": new DFWord("scar:scars", "scar:scars:scarred:scarred:scarring", null, null),
  "scold": new DFWord(null, "scold:scolds:scolded:scolded:scolding", null, null),
  "scorpion": new DFWord("scorpion:scorpions", null, null, null),
  "scratch": new DFWord("scratch:scratches", null, null, null),
  "scourge": new DFWord("scourge:scourges", null, null, null),
  "scream": new DFWord("scream:screams", "scream:screams:screamed:screamed:screaming", null, null),
  "scum": new DFWord("scum:scum", null, null, null),
  "sea": new DFWord("sea:seas", null, null, null),
  "seal_animal": new DFWord("seal:seals", null, null, null),
  "seal_art": new DFWord("seal:seals", null, null, null),
  "seam": new DFWord("seam:seams", null, null, null),
  "season": new DFWord("season:seasons", null, null, null),
  "secret": new DFWord("secret:secrets", "secret:secrets:secreted:secreted:secreting", "secretive", null),
  "seduce": new DFWord("seduction:seductions", "seduce:seduces:seduced:seduced:seducing", null, null),
  "seducer": new DFWord("seducer:seducers", null, null, null),
  "seed": new DFWord("seed:seeds", null, null, null),
  "seer": new DFWord("seer:seers", null, null, null),
  "seize": new DFWord("seizure:seizures", "seize:seizes:seized:seized:seizing", null, null),
  "serpent": new DFWord("serpent:serpents", null, null, null),
  "servant": new DFWord("servant:servants", null, null, null),
  "sever": new DFWord("sever:severs", "sever:severs:severed:severed:severing", null, null),
  "sewer": new DFWord("sewer:sewers", null, null, null),
  "shadow": new DFWord("shadow:shadows", null, null, null),
  "shaft": new DFWord("shaft:shafts", null, null, null),
  "shank": new DFWord("shank:shanks", null, null, null),
  "shark": new DFWord("shark:sharks", null, null, null),
  "shear": new DFWord(null, "shear:shears:sheared:shorn:shearing", null, null),
  "sheen": new DFWord("sheen:sheens", null, null, null),
  "shell": new DFWord("shell:shells", null, null, null),
  "shelter": new DFWord("shelter:shelters", "shelter:shelters:sheltered:sheltered:sheltering", null, null),
  "shield": new DFWord("shield:shields", "shield:shields:shielded:shielded:shielding", null, null),
  "shimmer": new DFWord(null, "shimmer:shimmers:shimmered:shimmered:shimmering", null, null),
  "ship": new DFWord("ship:ships", null, null, null),
  "shoot": new DFWord(null, "shoot:shoots:shot:shot:shooting", null, null),
  "show": new DFWord("show:shows", "show:shows:showed:shown:showing", null, null),
  "shred": new DFWord("shred:shreds", null, null, null),
  "shriek": new DFWord("shriek:shrieks", null, null, null),
  "shut": new DFWord(null, "shut:shuts:shut:shut:shutting", null, null),
  "sick": new DFWord("sick:sick", null, "sick", null),
  "sickness": new DFWord("sickness:sicknesses", null, null, null),
  "siege": new DFWord("siege:sieges", "siege:sieges:sieged:sieged:sieging", null, null),
  "silence": new DFWord("silence:silences", "silence:silences:silenced:silenced:silencing", "silent", null),
  "silk": new DFWord("silk:silks", null, "silken", null),
  "silky": new DFWord("silkiness:silkinesses", null, "silky", null),
  "silt": new DFWord("silt:silt", null, "silty", null),
  "silver": new DFWord("silver:silvers", null, "silvery", null),
  "sin": new DFWord("sin:sins", null, "sinful", null),
  "sinew": new DFWord("sinew:sinews", null, null, null),
  "sing": new DFWord("song:songs", "sing:sings:sang:sung:singing", null, null),
  "sister": new DFWord("sister:sisters", null, null, null),
  "skirt_clothing": new DFWord("skirt:skirts", null, null, null),
  "skirt_verb": new DFWord(null, "skirt:skirts:skirted:skirted:skirting", null, null),
  "skunk": new DFWord("skunk:skunks", null, null, null),
  "skull": new DFWord("skull:skulls", null, null, null),
  "sky": new DFWord("sky:skies", null, null, null),
  "slaughter": new DFWord("slaughter:slaughters", null, null, null),
  "slave": new DFWord("slave:slaves", null, null, null),
  "slayer": new DFWord("slayer:slayers", null, null, null),
  "slim": new DFWord(null, null, "slim", null),
  "slime": new DFWord("slime:slimes", null, null, null),
  "sling": new DFWord("sling:slings", null, null, null),
  "slink": new DFWord(null, "slink:slinks:slunk:slunk:slinking", null, null),
  "slit": new DFWord("slit:slits", "slit:slits:slit:slit:slitting", "slitted", null),
  "slither": new DFWord(null, "slither:slithers:slithered:slithered:slithering", null, null),
  "sliver": new DFWord("sliver:slivers", null, null, null),
  "sloth": new DFWord("sloth:sloths", null, null, null),
  "slug": new DFWord("slug:slugs", null, null, null),
  "smash": new DFWord(null, "smash:smashes:smashed:smashed:smashing", null, null),
  "smile": new DFWord("smile:smiles", "smile:smiles:smiled:smiled:smiling", null, null),
  "smith": new DFWord("smith:smiths", null, null, null),
  "smoke": new DFWord("smoke:smokes", null, null, null),
  "smooth": new DFWord("smoothness:smoothnesses", null, "smooth", null),
  "smoulder": new DFWord(null, "smoulder:smoulders:smouldered:smouldered:smouldering", null, null),
  "snack": new DFWord("snack:snacks", "snack:snacks:snacked:snacked:snacking", null, null),
  "snake": new DFWord("snake:snakes", null, null, null),
  "sneer": new DFWord("sneer:sneers", null, null, null),
  "snot": new DFWord("snot:snots", null, null, null),
  "snuggle": new DFWord(null, "snuggle:snuggles:snuggled:snuggled:snuggling", null, null),
  "soil": new DFWord("soil:soils", null, null, null),
  "soldier": new DFWord("soldier:soldiers", null, null, null),
  "soot": new DFWord("soot:soots", null, "sooty", null),
  "soothe": new DFWord(null, "soothe:soothes:soothed:soothed:soothing", null, null),
  "sorrow": new DFWord("sorrow:sorrows", null, null, null),
  "soul": new DFWord("soul:souls", null, null, null),
  "sound": new DFWord("sound:sounds", "sound:sounds:sounded:sounded:sounding", null, null),
  "south": new DFWord(null, null, "south", null),
  "spasm": new DFWord("spasm:spasms", null, null, null),
  "spawn": new DFWord("spawn:spawns", null, null, null),
  "spear": new DFWord("spear:spears", null, null, null),
  "spell": new DFWord("spell:spells", null, null, null),
  "spice": new DFWord("spice:spices", null, "spicy", null),
  "spider": new DFWord("spider:spiders", null, null, null),
  "spidery": new DFWord(null, null, "spidery", null),
  "spike": new DFWord("spike:spikes", null, null, null),
  "spin": new DFWord(null, "spin:spins:spun:spun:spinning", null, null),
  "spine": new DFWord("spine:spines", null, null, null),
  "spirit": new DFWord("spirit:spirits", null, null, null),
  "spit": new DFWord("spit:spits", null, null, null),
  "spittle": new DFWord("spittle:spittles", null, null, null),
  "spoil": new DFWord("spoils:spoils", "spoil:spoils:spoiled:spoiled:spoiling", null, null),
  "spoon": new DFWord("spoon:spoons", "spoon:spoons:spooned:spooned:spooning", null, null),
  "spring_noun": new DFWord("spring:springs", null, null, null),
  "spring_season": new DFWord("spring:springs", null, null, null),
  "spring_verb": new DFWord(null, "spring:springs:sprang:sprung:springing", null, null),
  "spry": new DFWord(null, null, "spry", null),
  "spy": new DFWord("spy:spies", null, null, null),
  "squid": new DFWord("squid:squids", null, null, null),
  "squirm": new DFWord(null, "squirm:squirms:squirmed:squirmed:squirming", null, null),
  "stab": new DFWord(null, "stab:stabs:stabbed:stabbed:stabbing", null, null),
  "staff": new DFWord("staff:staves", null, null, null),
  "stalk": new DFWord("stalker:stalkers", "stalk:stalks:stalked:stalked:stalking", null, null),
  "stand": new DFWord("stance:stances", "stand:stands:stood:stood:standing", null, null),
  "star": new DFWord("star:stars", null, null, null),
  "steed": new DFWord("steed:steeds", null, null, null),
  "starve": new DFWord("starvation:starvations", "starve:starves:starved:starved:starving", null, null),
  "steal": new DFWord(null, "steal:steals:stole:stolen:stealing", null, null),
  "steel": new DFWord("steel:steels", null, null, null),
  "stench": new DFWord("stench:stenches", null, null, null),
  "stern": new DFWord(null, null, "stern", null),
  "stick_wood": new DFWord("stick:sticks", null, null, null),
  "stick_verb": new DFWord(null, "stick:sticks:stuck:stuck:sticking", null, null),
  "sticky": new DFWord(null, null, "sticky", null),
  "stone": new DFWord("stone:stones", null, null, null),
  "storm": new DFWord("storm:storms", null, "stormy", null),
  "strange": new DFWord("strangeness:strangenesses", null, "strange", null),
  "strap": new DFWord("strap:straps", "strap:straps:strapped:strapped:strapping", null, null),
  "strapping_adj": new DFWord(null, null, "strapping", null),
  "straw": new DFWord("straw:straws", null, null, null),
  "stray": new DFWord("stray:strays", "stray:strays:strayed:strayed:straying", null, null),
  "strength": new DFWord("strength:strengths", null, "strong", null),
  "stretch": new DFWord(null, "stretch:stretches:stretched:stretched:stretching", null, null),
  "strike": new DFWord("strike:strikes", "strike:strikes:struck:stricken:striking", null, null),
  "stroke": new DFWord("stroke:strokes", "stroke:strokes:stroked:stroked:stroking", null, null),
  "stunt": new DFWord("stunt:stunts", null, null, null),
  "stunted": new DFWord(null, null, "stunted", null),
  "stupid": new DFWord(null, null, "stupid", null),
  "stutter": new DFWord(null, "stutter:stutters:stuttered:stuttered:stuttering", null, null),
  "style": new DFWord("style:styles", "style:styles:styled:styled:styling", null, null),
  "subordinate": new DFWord("subordinate:subordinates", null, "subordinate", null),
  "suck": new DFWord("sucker:suckers", "suck:sucks:sucked:sucked:sucking", null, null),
  "suffering": new DFWord("suffering:sufferings", null, null, null),
  "sugar": new DFWord("sugar:sugars", null, "sugary", null),
  "sully": new DFWord(null, "sully:sullies:sullied:sullied:sullying", null, null),
  "sullen": new DFWord(null, null, "sullen", null),
  "summer": new DFWord("summer:summers", null, null, null),
  "sun": new DFWord("sun:suns", null, "sunny", null),
  "sunder": new DFWord(null, "sunder:sunders:sundered:sunder:sundering", null, null),
  "supper": new DFWord("supper:suppers", null, null, null),
  "surprise": new DFWord("surprise:surprises", null, null, null),
  "swamp": new DFWord("swamp:swamps", null, null, null),
  "sweat": new DFWord("sweat:sweats", "sweat:sweats:sweat:sweat:sweating", null, null),
  "sweet": new DFWord("sweetness:sweetnesses", null, "sweet", null),
  "swift": new DFWord(null, null, "swift", null),
  "swim": new DFWord(null, "swim:swims:swam:swam:swimming", null, null),
  "swine": new DFWord("swine:swines", null, null, null),
  "sword": new DFWord("sword:swords", null, null, null),
  "syrup": new DFWord("syrup:syrups", null, null, null),
  "taker": new DFWord("taker:takers", "take:takes:took:taken:taking", null, null),
  "tail": new DFWord("tail:tails", null, null, null),
  "talon": new DFWord("talon:talons", null, null, null),
  "tar": new DFWord("tar:tars", null, null, null),
  "tarnish": new DFWord("tarnish:", "tarnish:tarnishes:tarnished:tarnished:tarnishing", null, null),
  "tell": new DFWord(null, "tell:tells:told:told:telling", null, null),
  "tempest": new DFWord("tempest:tempests", null, null, null),
  "tear": new DFWord("tear:tears", null, null, null),
  "terror": new DFWord("terror:terrors", "terrify:terrifies:terrified:terrified:terrifying", "terrible", null),
  "thief": new DFWord("thief:thieves", null, null, null),
  "thirst": new DFWord("thirst:thirsts", null, null, null),
  "thorn": new DFWord("thorn:thorns", null, null, null),
  "thrall": new DFWord("thrall:thralls", null, null, null),
  "three": new DFWord(null, null, null, "three"),
  "throat": new DFWord("throat:throats", null, null, null),
  "throne": new DFWord("throne:thrones", null, null, null),
  "thrower": new DFWord("thrower:throwers", "throw:throws:threw:thrown:throwing", null, null),
  "thunder": new DFWord("thunder:thunders", "thunder:thunders:thundered:thundered:thundering", null, null),
  "tick_animal": new DFWord("tick:ticks", null, null, null),
  "tick_clock": new DFWord("tick:ticks", "tick:ticks:ticked:ticked:ticking", null, null),
  "tight": new DFWord("tightness:tightnesses", null, "tight", null),
  "time": new DFWord("time:times", null, null, null),
  "toad": new DFWord("toad:toads", null, null, null),
  "toe": new DFWord("toe:toes", null, null, null),
  "tomb": new DFWord("tomb:tombs", null, null, null),
  "tome": new DFWord("tome:tomes", null, null, null),
  "tongue": new DFWord("tongue:tongues", null, null, null),
  "tooth": new DFWord("tooth:teeth", null, null, null),
  "torment": new DFWord("torment:torments", "torment:torments:tormented:tormented:tormenting", null, null),
  "touch": new DFWord("touch:touches", "touch:touches:touched:touched:touching", null, null),
  "touchy": new DFWord(null, null, "touchy", null),
  "tour": new DFWord("tour:tours", null, null, null),
  "tower": new DFWord("tower:towers", null, null, null),
  "trail": new DFWord("trail:trails", "trail:trails:trailed:trailed:trailing", null, null),
  "trance": new DFWord("trance:trances", null, null, null),
  "trap": new DFWord("trap:traps", "trap:traps:trapped:trapped:trapping", null, null),
  "trash": new DFWord("trash:trashes", null, null, null),
  "treasure": new DFWord("treasure:treasures", null, null, null),
  "treat": new DFWord("treat:treats", "treat:treats:treated:treated:treating", null, null),
  "treaty": new DFWord("treaty:treaties", null, null, null),
  "trench": new DFWord("trench:trenches", null, null, null),
  "tribe": new DFWord("tribe:tribes", null, null, null),
  "trick": new DFWord("trick:tricks", "trick:tricks:tricked:tricked:tricking", null, null),
  "trickery": new DFWord("trickery:trickeries", null, null, null),
  "trim": new DFWord("trim:trims", "trim:trims:trimmed:trimmed:trimming", "trim", null),
  "trouble": new DFWord("trouble:troubles", "trouble:troubles:troubled:troubled:troubling", null, null),
  "tug": new DFWord(null, "tug:tugs:tugged:tugged:tugging", null, null),
  "tulip": new DFWord("tulip:tulips", null, null, null),
  "tumble": new DFWord(null, "tumble:tumbles:tumbled:tumbled:tumbling", null, null),
  "tumor": new DFWord("tumor:tumors", null, null, null),
  "turmoil": new DFWord("turmoil:turmoils", null, null, null),
  "tusk": new DFWord("tusk:tusks", null, null, null),
  "tweet": new DFWord(null, "tweet:tweets:tweeted:tweeted:tweeting", null, null),
  "twilight": new DFWord("twilight:twilights", null, null, null),
  "twine": new DFWord("twine:twines", null, null, null),
  "twist": new DFWord("twist:twists", "twist:twists:twisted:twisted:twisting", null, null),
  "two": new DFWord(null, null, null, "two"),
  "ugly": new DFWord("ugliness:uglinesses", null, "ugly", null),
  "ulcer": new DFWord("ulcer:ulcers", null, null, null),
  "ultimate": new DFWord(null, null, "ultimate", null),
  "ultra": new DFWord(null, null, null, "ultra"),
  "unbridled": new DFWord(null, null, "unbridled", null),
  "uncle": new DFWord("uncle:uncles", null, null, null),
  "ungodly": new DFWord(null, null, "ungodly", null),
  "unholy": new DFWord("unholiness:unholinesses", null, "unholy", null),
  "unkempt": new DFWord(null, null, "unkempt", null),
  "unseen": new DFWord(null, null, "unseen", null),
  "untoward": new DFWord(null, null, "untoward", null),
  "unwelcome": new DFWord(null, null, "unwelcome", null),
  "urn": new DFWord("urn:urns", null, null, null),
  "useless": new DFWord(null, null, "useless", null),
  "vandal": new DFWord("vandal:vandals", "vandalize:vandalizes:vandalized:vandalized:vandalizing", null, null),
  "vegetable": new DFWord("vegetable:vegetables", null, null, null),
  "vegetate": new DFWord(null, "vegetate:vegetates:vegetated:vegetated:vegetating", null, null),
  "vegetation": new DFWord("vegetation:vegetations", null, null, null),
  "velvet": new DFWord("velvet:velvets", null, "velvety", null),
  "venom": new DFWord("venom:venoms", null, null, null),
  "vermin": new DFWord("vermin:vermins", null, null, null),
  "vile": new DFWord("vileness:vilenesses", null, "vile", null),
  "violator": new DFWord("violator:violators", null, null, null),
  "violence": new DFWord("violence:violences", null, "violent", null),
  "violet": new DFWord(null, null, "violet", null),
  "viper": new DFWord("viper:vipers", null, null, null),
  "voice": new DFWord("voice:voices", "voice:voices:voiced:voiced:voicing", null, null),
  "void": new DFWord("void:voids", null, null, null),
  "volcano": new DFWord("volcano:volcanos", null, null, null),
  "vomit": new DFWord("vomit:vomits", null, null, null),
  "vulture": new DFWord("vulture:vultures", null, null, null),
  "wail": new DFWord("wail:wails", null, null, null),
  "wander": new DFWord("wanderer:wanderers", "wander:wanders:wandered:wandered:wandering", null, null),
  "war": new DFWord("war:wars", null, null, null),
  "warrior": new DFWord("warrior:warriors", null, null, null),
  "wasp": new DFWord("wasp:wasps", null, null, null),
  "waste": new DFWord("waste:wastes", "waste:wastes:wasted:wasted:wasting", null, null),
  "watch": new DFWord("watch:watches", "watch:watches:watched:watched:watching", null, null),
  "watchful": new DFWord("watchfulness:watchfulnesses", null, "watchful", null),
  "wave": new DFWord("wave:waves", "wave:waves:waved:waved:waving", null, null),
  "wavy": new DFWord("waviness:wavinesses", null, "wavy", null),
  "wear": new DFWord(null, "wear:wears:wore:worn:wearing", null, null),
  "weasel": new DFWord("weasel:weasels", null, null, null),
  "weaver": new DFWord("weaver:weavers", null, null, null),
  "weed": new DFWord("weed:weeds", null, null, null),
  "weight": new DFWord("weight:weights", null, null, null),
  "weird": new DFWord("weird:weirds", null, null, null),
  "were": new DFWord(null, null, null, "were"),
  "west": new DFWord(null, null, "west", null),
  "wheat": new DFWord("wheat:wheats", null, null, null),
  "wheel": new DFWord("wheel:wheels", "wheel:wheels:wheeled:wheeled:wheeling", null, null),
  "whip": new DFWord("whip:whips", "whip:whips:whipped:whipped:whipping", null, null),
  "whisk": new DFWord(null, "whisk:whisks:whisked:whisked:whisking", null, null),
  "whisky": new DFWord("whisky:whiskies", null, null, null),
  "whisper": new DFWord("whisper:whispers", "whisper:whispers:whispered:whispered:whispering", null, null),
  "white": new DFWord("whiteness:whitenesses", null, "white", null),
  "wicked": new DFWord("wickedness:wickednesses", null, "wicked", null),
  "wild": new DFWord("wildness:wildnesses", null, "wild", null),
  "will": new DFWord("will:wills", null, "willful", null),
  "wing": new DFWord("wing:wings", null, null, null),
  "winter": new DFWord("winter:winters", null, null, null),
  "wisp": new DFWord("wisp:wisps", null, "wispy", null),
  "witch": new DFWord("witch:witches", null, null, null),
  "wonder_noun": new DFWord("wonder:wonders", null, "wondrous", null),
  "wonder_verb": new DFWord(null, "wonder:wonders:wondered:wondered:wondering", null, null),
  "wood": new DFWord("wood:woods", null, "wooden", null),
  "work": new DFWord("work:works", "work:works:worked:worked:working", null, null),
  "worm": new DFWord("worm:worms", null, null, null),
  "wraith": new DFWord("wraith:wraiths", null, null, null),
  "wrath": new DFWord("wrath:wraths", null, null, null),
  "wretch": new DFWord("wretch:wretches", null, "wretched", null),
  "yarn": new DFWord("yarn:yarns", null, null, null),
  "year": new DFWord("year:years", null, null, null),
  "yearling": new DFWord("yearling:yearlings", null, null, null),
  "yellow": new DFWord("yellowness:yellownesses", null, "yellow", null),
  "yore": new DFWord("yore:yore", null, null, null),
  "young": new DFWord(null, null, "young", null),
  "youth": new DFWord("youth:youths", null, "youthful", null),
  "zeal": new DFWord("zeal:zeals", null, null, null),
  "zealot": new DFWord("zealot:zealots", null, null, null),
  "zephyr": new DFWord("zephyr:zephyrs", null, null, null),
  "earth": new DFWord("earth:earths", null, "earthen", null),
  "artifact": new DFWord("artifact:artifacts", null, null, null),
  "bed": new DFWord("bed:beds", null, null, null),
  "bud": new DFWord("bud:buds", "bud:buds:budded:budded:budding", null, null),
  "cavort": new DFWord(null, "cavort:cavorts:cavorted:cavorted:cavorting", null, null),
  "common": new DFWord(null, null, "common", null),
  "daub": new DFWord("daub:daubs", "daub:daubs:daubed:daubed:daubing", null, null),
  "desert": new DFWord("desert:deserts", null, null, null),
  "dessert": new DFWord("dessert:desserts", null, null, null),
  "diamond": new DFWord("diamond:diamonds", null, "diamond", null),
  "domain": new DFWord("domain:domains", null, null, null),
  "dream": new DFWord("dream:dreams", "dream:dreams:dreamed:dreamed:dreaming", "dreamy", null),
  "tin": new DFWord("tin:tin", null, "tin", null),
  "copper": new DFWord("copper:copper", null, "copper", null),
  "bronze": new DFWord("bronze:bronze", null, "bronze", null),
  "granite": new DFWord("granite:granite", null, "granite", null),
  "fancy": new DFWord("fanciness:fancinesses", null, "fancy", null),
  "farm": new DFWord("farm:farms", "farm:farms:farmed:farmed:farming", null, null),
  "field": new DFWord("field:fields", null, null, null),
  "froth": new DFWord("froth:froths", "froth:froths:frothed:frothed:frothing", "frothy", null),
  "genius": new DFWord("genius:geniuses", null, null, null),
  "glacier": new DFWord("glacier:glaciers", null, "glacial", null),
  "gurgle": new DFWord(null, "gurgle:gurgles:gurgled:gurgled:gurgling", null, null),
  "impale": new DFWord(null, "impale:impales:impaled:impaled:impaling", null, null),
  "join": new DFWord(null, "join:joins:joined:joined:joining", null, null),
  "jade": new DFWord("jade:jades", null, "jade", null),
  "ivory": new DFWord("ivory:ivory", null, "ivory", null),
  "land": new DFWord("land:lands", null, null, null),
  "lather": new DFWord(null, "lather:lathers:lathered:lathered:lathering", null, null),
  "limp": new DFWord(null, null, "limp", null),
  "lucid": new DFWord("lucidity:lucidities", null, "lucid", null),
  "mountain": new DFWord("mountain:mountains", null, "mountainous", null),
  "mucus": new DFWord("mucus:mucuses", null, "mucous", null),
  "pattern": new DFWord("pattern:patterns", "pattern:patterns:patterned:patterned:patterning", "patterned", null),
  "pebble": new DFWord("pebble:pebbles", null, null, null),
  "boulder": new DFWord("boulder:boulders", null, null, null),
  "petal": new DFWord("petal:petals", null, null, null),
  "droplet": new DFWord("droplet:droplets", null, null, null),
  "pinnacle": new DFWord("pinnacle:pinnacles", null, null, null),
  "plain": new DFWord("plain:plains", null, null, null),
  "plainadj": new DFWord(null, null, "plain", null),
  "point": new DFWord("point:points", null, "pointy", null),
  "pot": new DFWord("pot:pots", null, null, null),
  "prairie": new DFWord("prairie:prairies", null, null, null),
  "rush": new DFWord(null, "rush:rushes:rushed:rushed:rushing", null, null),
  "slap": new DFWord(null, "slap:slaps:slapped:slapped:slapping", null, null),
  "sleeve": new DFWord("sleeve:sleeves", null, null, null),
  "speck": new DFWord("speck:specks", null, "speckled", null),
  "steppe": new DFWord("steppe:steppes", null, null, null),
  "tentacle": new DFWord("tentacle:tentacles", null, null, null),
  "test": new DFWord("test:tests", "test:tests:tested:tested:testing", null, null),
  "trot": new DFWord(null, "trot:trots:trotted:trotted:trotting", null, null),
  "tub": new DFWord("tub:tubs", null, null, null),
  "tube": new DFWord("tube:tubes", null, null, null),
  "tundra": new DFWord("tundra:tundras", null, null, null),
  "un": new DFWord(null, null, null, "un"),
  "waddle": new DFWord(null, "waddle:waddles:waddled:waddled:waddling", null, null),
  "wealth": new DFWord("wealth:wealths", null, "wealthy", null),
  "weevil": new DFWord("weevil:weevils", null, null, null),
  "whim": new DFWord("whim:whims", null, "whimsical", null),
  "whirl": new DFWord(null, "whirl:whirls:whirled:whirled:whirling", "whirling", null),
  "world": new DFWord("world:worlds", null, null, null),
  "desertverb": new DFWord(null, "desert:deserts:deserted:deserted:deserting", null, null),
  "fancyverb": new DFWord(null, "fancy:fancies:fancied:fancied:fancying", null, null),
  "limpverb": new DFWord(null, "limp:limps:limped:limped:limping", null, null),
  "pointverb": new DFWord(null, "point:points:pointed:pointed:pointing", null, null),
  "pantomime": new DFWord("pantomime:pantomimes", "pantomime:pantomimes:pantomimed:pantomimed:pantomiming", null, null),
  "sludge": new DFWord("sludge:sludges", null, null, null),
  "goo": new DFWord("goo:goos", null, null, null),
  "realm": new DFWord("realm:realms", null, null, null),
  "plane": new DFWord("plane:planes", null, null, null),
  "dimension": new DFWord("dimension:dimensions", null, null, null),
  "universe": new DFWord("universe:universes", null, null, null),
  "planet": new DFWord("planet:planets", null, null, null),
  "livid": new DFWord(null, null, "livid", null),
  "tepid": new DFWord(null, null, "tepid", null),
  "honest": new DFWord("honesty:honesties", null, "honest", null),
  "kingdom": new DFWord("kingdom:kingdoms", null, null, null),
  "empire": new DFWord("empire:empires", null, "imperial", null),
  "confederation": new DFWord("confederation:confederations", null, null, null),
  "simple": new DFWord("simplicity:simplicities", null, "simple", null),
  "trooper": new DFWord("trooper:troopers", null, null, null),
  "crimson": new DFWord(null, null, "crimson", null),
  "dune": new DFWord("dune:dunes", null, null, null),
  "direct": new DFWord("direction:directions", "direct:directs:directed:directed:directing", null, null),
  "clash": new DFWord("clash:clashes", "clash:clashes:clashed:clashed:clashing", null, null),
  "forever": new DFWord("forever:forever", null, null, null),
  "subtle": new DFWord("subtlety:subtleties", null, "subtle", null),
  "puppet": new DFWord("puppet:puppets", null, null, null),
  "hegemon": new DFWord("hegemon:hegemons", null, null, null),
  "amaze": new DFWord("amazement:amazements", "amaze:amazes:amazed:amazed:amazing", "amazing", null),
  "alchemy": new DFWord("alchemy:alchemies", null, "alchemical", null),
  "insight": new DFWord("insight:insights", null, "insightful", null),
  "union": new DFWord("union:unions", "unite:unites:united:united:uniting", null, null),
  "confederacy": new DFWord("confederacy:confederacies", "confederate:confederates:confederated:confederated:confederating", null, null),
  "speak": new DFWord("speech:speeches", "speak:speaks:spoke:spoken:speaking", null, null),
  "speaker": new DFWord("speaker:speakers", null, null, null),
  "persuade": new DFWord("persuasion:persuasions", "persuade:persuades:persuaded:persuaded:persuading", "persuasive", null),
  "persuader": new DFWord("persuader:persuaders", null, null, null),
  "control": new DFWord("control:controls", "control:controls:controlled:controlled:controlling", null, null),
  "controller": new DFWord("controller:controllers", null, null, null),
  "worker": new DFWord("worker:workers", null, null, null),
  "basic": new DFWord("basis:bases", null, "basic", null),
  "system": new DFWord("system:systems", null, "systemic", null),
  "ray": new DFWord("ray:rays", null, null, null),
  "target": new DFWord("target:targets", "target:targets:targeted:targeted:targeting", null, null),
  "bank": new DFWord("bank:banks", null, null, null),
  "clean": new DFWord(null, "clean:cleans:cleaned:cleaned:cleaning", "clean", null),
  "drive": new DFWord(null, "drive:drives:drove:driven:driving", null, null),
  "soar": new DFWord(null, "soar:soars:soared:soared:soaring", null, null),
  "glory": new DFWord("glory:glories", "glorify:glorifies:glorified:glorified:glorifying", "glorious", null),
  "dismal": new DFWord(null, null, "dismal", null),
  "radiance": new DFWord("radiance:radiances", null, "radiant", null),
  "pine": new DFWord("pine:pines", null, null, null),
  "messiah": new DFWord("messiah:messiahs", null, "messianic", null),
  "craft": new DFWord("craft:crafts", "craft:crafts:crafted:crafted:crafting", null, null),
  "labor": new DFWord("labor:labors", "labor:labors:labored:labored:laboring", "laborious", null),
  "discover": new DFWord("discovery:discoveries", "discover:discovers:discovered:discovered:discovering", null, null),
  "proliferate": new DFWord("proliferation:proliferations", "proliferate:proliferates:proliferated:proliferated:proliferating", null, null),
  "chunk": new DFWord("chunk:chunks", null, "chunky", null),
  "slip": new DFWord(null, "slip:slips:slipped:slipped:slipping", "slippery", null),
  "slide": new DFWord(null, "slide:slides:slid:slid:sliding", null, null),
  "single": new DFWord(null, null, "single", null),
  "pick": new DFWord("pick:picks", null, null, null),
  "tongs": new DFWord("tongs:tongs", null, null, null),
  "permanency": new DFWord("permanency:permanencies", null, "permanent", null),
  "responsible": new DFWord("responsibility:responsibilities", null, "responsible", null),
  "require": new DFWord("requirement:requirements", "require:requires:required:required:requiring", null, null),
  "rootverb": new DFWord("rooter:rooters", "root:roots:rooted:rooted:rooting", null, null),
  "blizzard": new DFWord("blizzard:blizzards", null, null, null),
  "general": new DFWord("general:generals", null, null, null),
  "memory": new DFWord("memory:memories", null, null, null),
  "construct": new DFWord("construct:constructs", "construct:constructs:constructed:constructed:constructing", "constructive", null),
  "lamb": new DFWord("lamb:lambs", null, null, null),
  "toast": new DFWord("toast:toasts", null, null, null),
  "gross": new DFWord(null, null, "gross", null),
  "paddle": new DFWord("paddle:paddles", "paddle:paddles:paddled:paddled:paddling", null, null),
  "council": new DFWord("council:councils", null, null, null),
  "counsel": new DFWord("counsellor:counsellors", "counsel:counsels:counselled:counselled:counseling", null, null),
  "gnarled": new DFWord(null, null, "gnarled", null),
  "stump": new DFWord("stump:stumps", null, null, null),
  "naughty": new DFWord("naughtiness:", null, "naughty", null),
  "play_fun": new DFWord("play:", "play:plays:played:played:playing", "playful", null),
  "play_perform": new DFWord("play:plays", "play:plays:played:played:playing", null, null),
  "flimsy": new DFWord("flimsiness:", null, "flimsy", null),
  "greater": new DFWord(null, null, "greater", null),
  "greatest": new DFWord(null, null, "greatest", null),
  "lesser": new DFWord(null, null, "lesser", null),
  "least": new DFWord(null, null, "least", null),
  "beard": new DFWord("beard:beards", null, "bearded", null),
  "dread": new DFWord("dread:", "dread:dreads:dreaded:dreaded:dreading", "dreadful", null),
  "wall": new DFWord("wall:walls", null, "walled", null),
  "wallow": new DFWord(null, "wallow:wallows:wallowed:wallowed:wallowing", null, null),
  "tax": new DFWord("tax:taxes", "tax:taxes:taxed:taxed:taxing", null, null),
  "fragrance": new DFWord("fragrance:fragrances", null, "fragrant", null),
  "lantern": new DFWord("lantern:lanterns", null, null, null),
  "torch": new DFWord("torch:torches", null, null, null),
  "water": new DFWord("water:waters", null, null, null),
  "wine": new DFWord("wine:wines", null, null, null),
  "line": new DFWord("line:lines", null, "lined", null),
  "mine": new DFWord("mine:mines", "mine:mines:mined:mined:mining", null, null),
  "wax": new DFWord("wax:waxes", "wax:waxes:waxed:waxed:waxing", "waxy", null),
  "shove": new DFWord("shove:shoves", "shove:shoves:shoved:shoved:shoving", null, null),
  "shovel": new DFWord("shovel:shovels", "shovel:shovels:shoveled:shoveled:shoveling", null, null),
  "melt": new DFWord(null, "melt:melts:melted:melted:melting", null, null),
  "wrack": new DFWord("wrack:", "wrack:wracks:wracked:wracked:wracking", null, null),
  "glitter": new DFWord("glitter:", "glitter:glitters:glittered:glittered:glittering", "glittery", null),
  "spark": new DFWord("spark:sparks", null, null, null),
  "sparkle": new DFWord("sparkle:sparkles", "sparkle:sparkles:sparkled:sparkled:sparkling", null, null),
  "flash": new DFWord("flash:flashes", "flash:flashes:flashed:flashed:flashing", null, null),
  "gleam": new DFWord("gleam:gleams", "gleam:gleams:gleamed:gleamed:gleaming", null, null),
  "run": new DFWord(null, "run:runs:ran:run:running", null, null),
  "dash": new DFWord(null, "dash:dashes:dashed:dashed:dashing", null, null),
  "savant": new DFWord("savant:savants", null, null, null),
  "roar": new DFWord("roar:roars", "roar:roars:roared:roared:roaring", null, null),
  "yell": new DFWord("yell:yells", "yell:yells:yelled:yelled:yelling", null, null),
  "echo": new DFWord("echo:echoes", "echo:echoes:echoed:echoed:echoing", null, null),
  "hollow": new DFWord("hollow:hollows", null, "hollow", null),
  "full": new DFWord(null, "fill:fills:filled:filled:filling", "full", null),
  "empty": new DFWord("emptiness:", "empty:empties:emptied:emptied:emptying", "empty", null),
  "word": new DFWord("word:words", null, "wordy", null),
  "intense": new DFWord("intensity:", null, "intense", null),
  "sad": new DFWord("sadness:sadnesses", null, "sad", null),
  "glad": new DFWord("gladness:", null, "glad", null),
  "tragedy": new DFWord("tragedy:tragedies", null, "tragic", null),
  "bell": new DFWord("bell:bells", null, null, null),
  "belly": new DFWord("belly:bellies", null, null, null),
  "tummy": new DFWord("tummy:tummies", null, null, null),
  "shock": new DFWord("shock:shocks", "shock:shocks:shocked:shocked:shocking", null, null),
  "ponder": new DFWord(null, "ponder:ponders:pondered:pondered:pondering", null, null),
  "ponderous": new DFWord(null, null, "ponderous", null),
  "heaven": new DFWord("heaven:heavens", null, "heavenly", null),
  "round": new DFWord(null, null, "round", null),
  "rounded": new DFWord(null, null, "rounded", null),
  "unspeakable": new DFWord(null, null, "unspeakable", null),
  "unthinkable": new DFWord(null, null, "unthinkable", null),
  "unnameable": new DFWord(null, null, "unnameable", null),
  "calamity": new DFWord("calamity:calamities", null, "calamitous", null),
  "cyclopean": new DFWord(null, null, "cyclopean", null),
  "tame": new DFWord(null, "tame:tames:tamed:tamed:taming", "tame", null),
  "untamed": new DFWord(null, null, "untamed", null),
  "burst": new DFWord(null, "burst:bursts:burst:burst:bursting", null, null),
  "meteor": new DFWord("meteor:meteors", null, null, null),
  "trite": new DFWord(null, null, "trite", null),
  "trifle": new DFWord("trifle:trifles", null, "trifling", null),
  "rank": new DFWord(null, null, "rank", null),
  "rancor": new DFWord("rancor:", null, "rancorous", null),
  "bitter": new DFWord("bitterness:", null, "bitter", null),
  "sour": new DFWord("sourness:", null, "sour", null),
  "danger": new DFWord("danger:dangers", null, "dangerous", null),
  "impervious": new DFWord(null, null, "impervious", null),
  "unswerving": new DFWord(null, null, "unswerving", null),
  "sway": new DFWord(null, "sway:sways:swayed:swayed:swaying", null, null),
  "luxury": new DFWord("luxury:luxuries", null, "luxurious", null),
  "euphoria": new DFWord("euphoria:", null, "euphoric", null),
  "scintillate": new DFWord(null, "scintillate:scintillates:scintillated:scintillated:scintillating", "scintillating", null),
  "divine": new DFWord(null, null, "divine", null),
  "divide": new DFWord(null, "divide:divides:divided:divided:dividing", null, null),
  "taint": new DFWord(null, "taint:taints:tainted:tainted:tainting", "tainted", null),
  "corrupt": new DFWord("corruption:", "corrupt:corrupts:corrupted:corrupted:corrupting", "corrupt", null),
  "malign_slander_v": new DFWord(null, "malign:maligns:maligned:maligned:maligning", "maligned", null),
  "malign_adj": new DFWord(null, null, "malign", null),
  "malignant": new DFWord(null, null, "malignant", null),
  "trust": new DFWord("trust:", "trust:trusts:trusted:trusted:trusting", null, null),
  "distrust": new DFWord("distrust:", "distrust:distrusts:distrusted:distrusted:distrusting", null, null),
  "trustworthy": new DFWord(null, null, "trustworthy", null),
  "untrustworthy": new DFWord(null, null, "untrustworthy", null),
  "dishonest": new DFWord(null, null, "dishonest", null),
  "blight": new DFWord("blight:blights", "blight:blights:blighted:blighted:blighting", "blighted", null),
  "cunning": new DFWord(null, null, "cunning", null),
  "sly": new DFWord(null, null, "sly", null),
  "rustic": new DFWord(null, null, "rustic", null),
  "homely": new DFWord(null, null, "homely", null),
  "lonely": new DFWord(null, null, "lonely", null),
  "lonesome": new DFWord(null, null, "lonesome", null),
  "close": new DFWord(null, "close:closes:closed:closed:closing", "closed", null),
  "ford": new DFWord("ford:fords", "ford:fords:forded:forded:fording", null, null),
  "way": new DFWord("way:ways", null, null, null),
  "wayward": new DFWord(null, null, "wayward", null),
  "bandit": new DFWord("bandit:bandits", null, null, null),
  "brigand": new DFWord("brigand:brigands", null, null, null),
  "monster": new DFWord("monster:monsters", null, "monstrous", null),
  "sense": new DFWord("sense:senses", "sense:senses:sensed:sensed:sensing", "sensitive", null),
  "insensitive": new DFWord(null, null, "insensitive", null),
  "sensual": new DFWord(null, null, "sensual", null),
  "odor": new DFWord("odor:odors", null, "odorous", null),
  "malodorous": new DFWord("malodor:malodors", null, "malodorous", null),
  "ignorant": new DFWord("ignorance:", null, "ignorant", null),
  "ignoble": new DFWord(null, null, "ignoble", null),
  "humble": new DFWord("humility:", null, "humble", null),
  "moral": new DFWord("moral:morals", null, "moral", null),
  "immoral": new DFWord(null, null, "immoral", null),
  "superior": new DFWord(null, null, "superior", null),
  "inferior": new DFWord(null, null, "inferior", null),
  "infinite": new DFWord(null, null, "infinite", null),
  "sable_color": new DFWord(null, null, "sable", null),
  "fell_cruel": new DFWord(null, null, "fell", null),
  "fell_hide": new DFWord("fell:fells", null, null, null),
  "pelt_hide": new DFWord("pelt:pelts", null, null, null),
  "dignity": new DFWord("dignity:", null, "dignified", null),
  "undignified": new DFWord(null, null, "undignified", null),
  "indignation": new DFWord("indignation:", null, null, null),
  "right_better": new DFWord("right:rights", null, "righteous", null),
  "absolute": new DFWord(null, null, "absolute", null),
  "fair_even": new DFWord(null, null, "fair", null),
  "fair_color": new DFWord(null, null, "fair", null),
  "snarl": new DFWord("snarl:snarls", "snarl:snarls:snarled:snarled:snarling", "snarling", null),
  "loyal": new DFWord("loyalty:", null, "loyal", null),
  "disloyal": new DFWord("disloyalty:", null, "disloyal", null),
  "help": new DFWord("help:", "help:helps:helped:helped:helping", "helpful", null),
  "friend": new DFWord("friend:friends", null, "friendly", null),
  "courtesy": new DFWord("courtesy:courtesies", null, "courteous", null),
  "cheerful": new DFWord(null, null, "cheerful", null),
  "thrift": new DFWord("thrift:", null, "thrifty", null),
  "bravery": new DFWord("bravery:", "brave:braves:braved:braved:braving", "brave", null),
  "revere": new DFWord("reverence:reverences", "revere:reveres:revered:revered:revering", "revered", null),
  "venerate": new DFWord("veneration:", "venerate:venerates:venerated:venerated:venerating", "venerable", null),
  "salute": new DFWord("salute:salutes", "salute:salutes:saluted:saluted:saluting", null, null),
  "nation": new DFWord("nation:nations", null, "national", null),
  "drink": new DFWord("drink:drinks", "drink:drinks:drank:drunken:drinking", null, null),
  "considerate": new DFWord("consideration:", null, "considerate", null),
  "convenient": new DFWord("convenience:conveniences", null, "convenient", null),
  "inconsiderate": new DFWord(null, null, "inconsiderate", null),
  "inconvenient": new DFWord("inconvenience:inconveniences", null, "inconvenient", null),
  "continent": new DFWord("continent:continents", null, null, null),
  "flight": new DFWord("flight:flights", null, null, null),
  "flighty": new DFWord(null, null, "flighty", null),
  "gild": new DFWord(null, "gild:gilds:gilt:gilt:gilding", "gilded", null),
  "guild": new DFWord("guild:guilds", null, null, null),
  "merchant": new DFWord("merchant:merchants", null, null, null),
  "trade": new DFWord("trade:trades", "trade:trades:traded:traded:trading", null, null),
  "plait": new DFWord("plait:plaits", "plait:plaits:plaited:plaited:plaiting", "plaited", null),
  "braid": new DFWord("braid:braids", "braid:braids:braided:braided:braiding", "braided", null),
  "pleat": new DFWord("pleat:pleats", "pleat:pleats:pleated:pleated:pleating", "pleated", null),
  "fold": new DFWord("fold:folds", "fold:folds:folded:folded:folding", "folded", null),
  "bunion": new DFWord("bunion:bunions", null, null, null),
  "uncommon": new DFWord(null, null, "uncommon", null),
  "rare": new DFWord(null, null, "rare", null),
  "scarce": new DFWord(null, null, "scarce", null),
  "remarkable": new DFWord(null, null, "remarkable", null),
  "unremarkable": new DFWord(null, null, "unremarkable", null),
  "solitary": new DFWord(null, null, "solitary", null),
  "bend": new DFWord("bend:bends", "bend:bends:bent:bent:bending", "bent", null),
  "blame": new DFWord("blame:blames", "blame:blames:blamed:blamed:blaming", null, null),
  "blameless": new DFWord(null, null, "blameless", null),
  "twig": new DFWord("twig:twigs", null, null, null),
  "girder": new DFWord("girder:girders", null, null, null),
  "truss": new DFWord("truss:trusses", "truss:trusses:trussed:trussed:trussing", "trussed", null),
  "skewer": new DFWord("skewer:skewers", "skewer:skewers:skewered:skewered:skewering", "skewered", null),
  "sculpt": new DFWord("sculpture:sculptures", "sculpt:sculpts:sculpted:sculpted:sculpting", "sculpted", null),
  "tress": new DFWord("tress:tresses", null, "tressed", null),
  "lock_hair": new DFWord("lock:locks", null, null, null),
  "curl": new DFWord("curl:curls", "curl:curls:curled:curled:curling", "curled", null),
  "tuft": new DFWord("tuft:tufts", null, "tufted", null),
  "mean_nasty": new DFWord("meanness:", null, "mean", null),
  "mean_low": new DFWord("meanness:", null, "mean", null),
  "shame": new DFWord("shame:shames", "shame:shames:shamed:shamed:shaming", "shameful", null),
  "ashamed": new DFWord(null, null, "ashamed", null),
  "steam": new DFWord("steam:", null, "steamy", null),
  "fern": new DFWord("fern:ferns", null, null, null),
  "ferry": new DFWord("ferry:ferries", "ferry:ferries:ferried:ferried:ferrying", null, null),
  "gully": new DFWord("gully:gullies", null, null, null),
  "worry": new DFWord("worry:worries", "worry:worries:worried:worried:worrying", "worried", null),
  "lion": new DFWord("lion:lions", null, null, null),
  "cactus": new DFWord("cactus:cacti", null, null, null),
  "desk": new DFWord("desk:desks", null, null, null),
  "bottle": new DFWord("bottle:bottles", "bottle:bottles:bottled:bottled:bottling", null, null),
  "shingle": new DFWord("shingle:shingles", null, null, null),
  "blanket": new DFWord("blanket:blankets", null, "blanketed", null),
  "cloud": new DFWord("cloud:clouds", null, "cloudy", null),
  "plate": new DFWord("plate:plates", null, "plated", null),
  "road": new DFWord("road:roads", null, null, null),
  "house": new DFWord("house:houses", null, null, null),
  "bean": new DFWord("bean:beans", null, null, null),
  "block_defend": new DFWord(null, "block:blocks:blocked:blocked:blocking", null, null),
  "block_square": new DFWord("block:blocks", null, null, null),
  "fountain": new DFWord("fountain:fountains", null, null, null),
  "pearl": new DFWord("pearl:pearls", null, null, null),
  "pear": new DFWord("pear:pears", null, null, null),
  "thimble": new DFWord("thimble:thimbles", null, null, null),
  "bunny": new DFWord("bunny:bunnies", null, null, null),
  "rope": new DFWord("rope:ropes", null, null, null),
  "string": new DFWord("string:strings", null, null, null),
  "wire": new DFWord("wire:wires", null, null, null),
  "lash": new DFWord("lash:lashes", "lash:lashes:lashed:lashed:lashing", null, null),
  "sorcery": new DFWord("sorcery:", null, "sorcerous", null),
  "sorcerer": new DFWord("sorcerer:sorcerers", null, null, null),
  "ensorcel": new DFWord(null, "ensorcel:ensorcels:ensorceled:ensorceled:ensorceling", null, null),
  "bewitch": new DFWord(null, "bewitch:bewitches:bewitched:bewitched:bewitching", "bewitched", null),
  "magician": new DFWord("magician:magicians", null, null, null),
  "conjure": new DFWord(null, "conjure:conjures:conjured:conjured:conjuring", null, null),
  "conjurer": new DFWord("conjurer:conjurers", null, null, null),
  "enchant": new DFWord("enchantment:enchantments", "enchant:enchants:enchanted:enchanted:enchanting", "enchanted", null),
  "enchanter": new DFWord("enchanter:enchanters", null, null, null),
  "lance": new DFWord("lance:lances", "lance:lances:lanced:lanced:lancing", null, null),
  "lancer": new DFWord("lancer:lancers", null, null, null),
  "stink": new DFWord(null, "stink:stinks:stank:stank:stinking", "stinky", null),
  "freckle": new DFWord("freckle:freckles", null, null, null),
  "pimple": new DFWord("pimple:pimples", null, null, null),
  "wart": new DFWord("wart:warts", null, null, null),
  "blotch": new DFWord("blotch:blotches", null, null, null),
  "callus": new DFWord("callus:calluses", null, null, null),
  "wind_storm": new DFWord("wind:winds", null, "windy", null),
  "wind_clock": new DFWord(null, "wind:winds:wound:wound:winding", null, null),
  "haze": new DFWord("haze:", null, "hazy", null),
  "skin": new DFWord("skin:skins", null, null, null),
  "shin": new DFWord("shin:shins", null, null, null),
  "torture": new DFWord("torture:tortures", "torture:tortures:tortured:tortured:torturing", "torturous", null),
  "tool": new DFWord("tool:tools", null, null, null),
  "scholar": new DFWord("scholar:scholars", null, "scholarly", null),
  "weep": new DFWord(null, "weep:weeps:wept:wept:weeping", null, null),
  "massive": new DFWord(null, null, "massive", null),
  "large": new DFWord(null, null, "large", null),
  "small": new DFWord(null, null, "small", null),
  "loaf": new DFWord("loaf:loaves", null, null, null),
  "tile": new DFWord("tile:tiles", null, "tiled", null),
  "sport": new DFWord("sport:sports", null, null, null),
  "search": new DFWord("search:searches", "search:searches:searched:searched:searching", null, null),
  "stale": new DFWord(null, null, "stale", null),
  "fresh": new DFWord(null, null, "fresh", null),
  "rub": new DFWord(null, "rub:rubs:rubbed:rubbed:rubbing", null, null),
  "worthy": new DFWord("worth:", null, "worthy", null),
  "worthless": new DFWord(null, null, "worthless", null),
  "fright": new DFWord("fright:", "frighten:frightens:frightened:frightened:frightening", "frightful", null),
  "revolting": new DFWord(null, "revolt:revolts:revolted:revolted:revolting", "revolting", null),
  "disgust": new DFWord("disgust:", "disgust:disgusts:disgusted:disgusted:disgusting", "disgusting", null),
  "outrage": new DFWord("outrage:", null, "outrageous", null),
  "cruel": new DFWord("cruelty:cruelties", null, "cruel", null),
  "deviant": new DFWord("deviance:", null, "deviant", null),
  "devious": new DFWord(null, null, "devious", null),
  "fabulous": new DFWord(null, null, "fabulous", null),
  "morality": new DFWord("morality:", null, null, null),
  "immorality": new DFWord("immorality:", null, null, null),
  "skin_verb": new DFWord(null, "skin:skins:skinned:skinned:skinning", null, null),
  "skinny": new DFWord(null, null, "skinny", null),
  "narrow": new DFWord(null, null, "narrow", null),
  "grief": new DFWord("grief:", "grieve:grieves:grieved:grieved:grieving", "grieving", null),
  "belt": new DFWord("belt:belts", null, "belted", null),
  "sandal": new DFWord("sandal:sandals", null, "sandaled", null),
  "future": new DFWord("future:", null, "future", null),
  "past": new DFWord("past:", null, "past", null),
  "present": new DFWord("present:", null, "present", null),
  "bite": new DFWord("bite:bites", "bite:bites:bit:bitten:biting", null, null),
  "split": new DFWord(null, "split:splits:split:split:splitting", "split", null),
  "brash": new DFWord(null, null, "brash", null),
  "brazen": new DFWord(null, null, "brazen", null),
  "strangle": new DFWord("strangulation:", "strangle:strangles:strangled:strangled:strangling", "strangled", null),
  "gallows": new DFWord("gallows:gallows", null, null, null),
  "muscle": new DFWord("muscle:muscles", null, "muscular", null),
  "dim": new DFWord(null, "dim:dims:dimmed:dimmed:dimming", "dim", null),
  "spurt": new DFWord("spurt:spurts", "spurt:spurts:spurted:spurted:spurting", "spurting", null),
  "gravel": new DFWord("gravel:", null, null, null),
  "risk": new DFWord("risk:risks", "risk:risks:risked:risked:risking", "risky", null),
  "chance": new DFWord("chance:chances", "chance:chances:chanced:chanced:chancing", null, null),
  "channel": new DFWord("channel:channels", "channel:channels:channeled:channeled:channeling", null, null),
  "mirth": new DFWord("mirth:", null, "mirthful", null),
  "glee": new DFWord("glee:", null, "gleeful", null),
  "victim": new DFWord("victim:victims", null, null, null),
  "stranger": new DFWord("stranger:strangers", null, null, null),
  "drab": new DFWord(null, null, "drab", null),
  "vision": new DFWord("vision:visions", null, null, null),
  "visionary": new DFWord("visionary:visionaries", null, null, null),
  "oracle": new DFWord("oracle:oracles", null, "oracular", null),
  "utter": new DFWord("utterance:utterances", "utter:utters:uttered:uttered:uttering", null, null),
  "decide": new DFWord("decision:decisions", "decide:decides:decided:decided:deciding", null, null),
  "decisive": new DFWord(null, null, "decisive", null),
  "special": new DFWord(null, null, "special", null),
  "author": new DFWord("author:authors", "author:authors:authored:authored:authoring", null, null),
  "authority": new DFWord("authority:", null, null, null),
  "portent": new DFWord("portent:portents", null, "portentous", null),
  "momentous": new DFWord(null, null, "momentous", null),
  "infallible": new DFWord(null, null, "infallible", null),
  "ambiguous": new DFWord("ambiguity:", null, "ambiguous", null),
  "curious": new DFWord("curiosity:", null, "curious", null),
  "obscure": new DFWord("obscurity:", null, "obscure", null),
  "obscene": new DFWord("obscenity:", null, "obscene", null),
  "obstacle": new DFWord("obstacle:obstacles", null, null, null),
  "vulgar": new DFWord("vulgarity:vulgarities", null, "vulgar", null),
  "ominous": new DFWord(null, null, "ominous", null),
  "island": new DFWord("island:islands", null, null, null),
  "church": new DFWord("church:churches", null, null, null),
  "temple": new DFWord("temple:temples", null, null, null),
  "cult": new DFWord("cult:cults", null, null, null),
  "coven": new DFWord("coven:covens", null, null, null),
  "sect": new DFWord("sect:sects", null, null, null),
  "order_group": new DFWord("order:orders", null, null, null),
  "creed": new DFWord("creed:creeds", null, null, null),
  "communion": new DFWord("communion:communions", null, null, null),
  "doctrine": new DFWord("doctrine:doctrines", null, null, null),
  "faith": new DFWord("faith:faiths", null, null, null),
  "denomination": new DFWord("denomination:denominations", null, null, null),
  "temple_head": new DFWord("temple:temples", null, null, null),
  "occult": new DFWord(null, null, "occult", null),
  "order_concept": new DFWord("order:", null, "ordered", null),
  "faith_loyalty": new DFWord("faith:", null, "faithful", null),
  "guilt": new DFWord("guilt:", null, "guilty", null),
  "baby": new DFWord("baby:babies", null, null, null),
  "bleed": new DFWord(null, "bleed:bleeds:bled:bled:bleeding", null, null),
  "girl": new DFWord("girl:girls", null, "girlish", null),
  "boy": new DFWord("boy:boys", null, "boyish", null),
  "woman": new DFWord("woman:women", null, null, null),
  "flag": new DFWord("flag:flags", null, null, null),
  "banner": new DFWord("banner:banners", null, null, null),
  "wreath": new DFWord("wreath:wreaths", null, null, null),
  "standard_flag": new DFWord("standard:standards", null, null, null),
  "trumpet": new DFWord("trumpet:trumpets", null, null, null),
  "goal": new DFWord("goal:goals", null, null, null),
  "jail": new DFWord("jail:jails", null, null, null),
  "prison": new DFWord("prison:prisons", null, null, null),
  "library": new DFWord("library:libraries", null, null, null),
  "avalanche": new DFWord("avalanche:avalanches", null, null, null),
  "stigma": new DFWord("stigma:stigmata", null, null, null),
  "slick": new DFWord(null, null, "slick", null),
  "talk": new DFWord(null, "talk:talks:talked:talked:talking", null, null),
  "jailer": new DFWord("jailer:jailers", null, null, null),
  "still_unmoving": new DFWord(null, "still:stills:stilled:stilled:stilling", "still", null),
  "calm": new DFWord("calm:", "calm:calms:calmed:calmed:calming", "calm", null),
  "hush": new DFWord(null, "hush:hushes:hushed:hushed:hushing", null, null),
  "laconic": new DFWord(null, null, "laconic", null),
  "lull": new DFWord("lull:lulls", "lull:lulls:lulled:lulled:lulling", null, null),
  "mute": new DFWord(null, "mute:mutes:muted:muted:muting", "mute", null),
  "noiseless": new DFWord("noiselessness:", null, "noiseless", null),
  "quiescent": new DFWord("quiescence:", null, "quiescent", null),
  "quiet": new DFWord("quietness:", "quiet:quiets:quieted:quieted:quieting", "quiet", null),
  "reticent": new DFWord("reticence:", null, "reticent", null),
  "saturnine": new DFWord("saturninity:", null, "saturnine", null),
  "speechless": new DFWord("speechlessness:", null, "speechless", null),
  "taciturn": new DFWord(null, null, "taciturn", null),
  "fortune_luck": new DFWord("fortune:fortunes", null, "fortunate", null),
  "fortune_wealth": new DFWord("fortune:fortunes", null, null, null),
  "accident": new DFWord("accident:accidents", null, "accidental", null),
  "circumstance": new DFWord("circumstance:circumstances", null, "circumstantial", null),
  "contingent": new DFWord("contingent:contingents", null, null, null),
  "contingency": new DFWord("contingency:contingencies", null, "contingent", null),
  "coincidence": new DFWord("coincidence:coincidences", null, "coincidental", null),
  "fluke": new DFWord("fluke:flukes", null, null, null),
  "luck": new DFWord("luck:", null, "lucky", null),
  "certain": new DFWord("certainty:", null, "certain", null),
  "uncertain": new DFWord("uncertainty:", null, "uncertain", null),
  "fortuitous": new DFWord(null, null, "fortuitous", null),
  "incidental": new DFWord("incident:incidents", null, "incidental", null),
  "amuse": new DFWord("amusement:", "amuse:amuses:amused:amused:amusing", "amusing", null),
  "pastime": new DFWord("pastime:pastimes", null, null, null),
  "distract_annoy": new DFWord("distraction:distractions", "distract:distracts:distracted:distracted:distracting", "distracting", null),
  "distraction_game": new DFWord("distraction:distractions", null, null, null),
  "diversion_game": new DFWord("diversion:diversions", null, null, null),
  "divert_detour": new DFWord("diversion:diversions", "divert:diverts:diverted:diverted:diverting", null, null),
  "recreation_game": new DFWord("recreation:recreations", null, null, null),
  "compete": new DFWord("competition:competitions", "compete:competes:competed:competed:competing", "competitive", null),
  "contest": new DFWord("contest:contests", "contest:contests:contested:contested:contesting", "contested", null),
  "match_contest": new DFWord("match:matches", "match:matches:matched:matched:matching", "matched", null),
  "match_equal": new DFWord("match:matches", "match:matches:matched:matched:matching", "matched", null),
  "tournament": new DFWord("tournament:tournaments", null, null, null),
  "plan": new DFWord("plan:plans", "plan:plans:planned:planned:planning", null, null),
  "scenario": new DFWord("scenario:scenarios", null, null, null),
  "strategy": new DFWord("strategy:strategies", null, "strategic", null),
  "tactic": new DFWord("tactic:tactics", null, "tactical", null),
  "autonomy": new DFWord("autonomy:", null, "autonomous", null),
  "emancipate": new DFWord("emancipation:", "emancipate:emancipates:emancipated:emancipated:emancipating", "emancipated", null),
  "extricate": new DFWord("extrication:", "extricate:extricates:extricated:extricated:extricating", null, null),
  "impunity": new DFWord("impunity:", null, null, null),
  "liberate": new DFWord("liberation:", "liberate:liberates:liberated:liberated:liberating", null, null),
  "liberty": new DFWord("liberty:liberties", null, null, null),
  "release": new DFWord("release:", "release:releases:released:released:releasing", "released", null),
  "relieve": new DFWord("relief:", "relieve:relieves:relieved:relieved:relieving", "relieved", null),
  "even": new DFWord("evenness:", "even:evens:evened:evened:evening", "even", null),
  "balance": new DFWord("balance:balances", "balance:balances:balanced:balanced:balancing", "balanced", null),
  "equity": new DFWord("equity:", null, null, null),
  "equivalence": new DFWord("equivalence:", null, "equivalent", null),
  "parity": new DFWord("parity:", null, null, null),
  "stasis": new DFWord("stasis:", null, "static", null),
  "symmetry": new DFWord("symmetry:symmetries", null, "symmetric", null),
  "equal": new DFWord("equality:", "equal:equals:equaled:equaled:equaling", "equal", null),
  "harmony": new DFWord("harmony:harmonies", "harmonize:harmonizes:harmonized:harmonized:harmonizing", "harmonious", null),
  "neutralize": new DFWord("neutralization:", "neutralize:neutralizes:neutralized:neutralized:neutralizing", null, null),
  "neutral": new DFWord("neutrality:", null, "neutral", null),
  "stable_unchanging": new DFWord("stability:", "stabilize:stabilizes:stabilized:stabilized:stabilizing", "stable", null),
  "lull_mislead": new DFWord(null, "lull:lulls:lulled:lulled:lulling", null, null),
  "dour": new DFWord("dourness:", null, "dour", null),
  "gloom": new DFWord("gloom:", null, "gloomy", null),
  "severe": new DFWord("severity:", null, "severe", null),
  "glum": new DFWord("glumness:", null, "glum", null),
  "image": new DFWord("image:images", null, null, null),
  "shrine": new DFWord("shrine:shrines", null, null, null),
  "sanctuary": new DFWord("sanctuary:sanctuaries", null, null, null),
  "monastery": new DFWord("monastery:monasteries", null, null, null),
  "convent": new DFWord("convent:convents", null, null, null),
  "cathedral": new DFWord("cathedral:cathedrals", null, null, null),
  "chapel": new DFWord("chapel:chapels", null, null, null),
  "sanctum": new DFWord("sanctum:sanctums", null, null, null),
  "inferno": new DFWord("inferno:infernos", null, null, null),
  "conflagration": new DFWord("conflagration:conflagrations", null, null, null),
  "flare": new DFWord("flare:flares", null, null, null),
  "heat": new DFWord("heat:", "heat:heats:heated:heated:heating", "hot", null),
  "sand": new DFWord("sand:sands", null, null, null),
  "pillar": new DFWord("pillar:pillars", null, null, null),
  "column": new DFWord("column:columns", null, null, null),
  "arch_noun": new DFWord("arch:arches", null, null, null),
  "grotto": new DFWord("grotto:grottoes", null, null, null),
  "cavern": new DFWord("cavern:caverns", null, "cavernous", null),
  "depth": new DFWord("depth:depths", null, null, null),
  "shade": new DFWord("shade:shades", null, "shady", null),
  "veil": new DFWord("veil:veils", "veil:veils:veiled:veiled:veiling", "veiled", null),
  "relic": new DFWord("relic:relics", null, null, null),
  "umbra": new DFWord("umbra:umbras", null, "umbral", null),
  "phantom": new DFWord("phantom:phantoms", null, null, null),
  "murk": new DFWord("murk:", null, "murky", null),
  "faint_intensity": new DFWord(null, null, "faint", null),
  "faint_verb": new DFWord(null, "faint:faints:fainted:fainted:fainting", null, null),
  "somber": new DFWord(null, null, "somber", null),
  "tenebrous": new DFWord(null, null, "tenebrous", null),
  "morose": new DFWord(null, null, "morose", null),
  "lover": new DFWord("lover:lovers", null, null, null),
  "beloved": new DFWord(null, null, "beloved", null),
  "escort": new DFWord("escort:escorts", "escort:escorts:escorted:escorted:escorting", "escorted", null),
  "suitor": new DFWord("suitor:suitors", null, null, null),
  "pungent": new DFWord("pungency:", null, "pungent", null),
  "musty": new DFWord(null, null, "musty", null),
  "infect": new DFWord("infection:", "infect:infects:infected:infected:infecting", "infected", null),
  "lesion": new DFWord("lesion:lesions", null, null, null),
  "afflict": new DFWord("affliction:afflictions", "afflict:afflicts:afflicted:afflicted:afflicting", "afflicted", null),
  "ache": new DFWord("ache:aches", "ache:aches:ached:ached:aching", "aching", null),
  "epidemic": new DFWord("epidemic:epidemics", null, "epidemic", null),
  "fight": new DFWord("fight:fights", "fight:fights:fought:fought:fighting", null, null),
  "scuffle": new DFWord("scuffle:scuffles", "scuffle:scuffles:scuffled:scuffled:scuffling", null, null),
  "yawn": new DFWord("yawn:yawns", "yawn:yawns:yawned:yawned:yawning", "yawning", null),
  "action": new DFWord("action:actions", null, null, null),
  "assault": new DFWord("assault:assaults", "assault:assaults:assaulted:assaulted:assaulting", "assaulted", null),
  "carnal": new DFWord("carnality:", null, "carnal", null),
  "strife": new DFWord("strife:", null, "strifeful", null),
  "combat": new DFWord("combat:combats", "combat:combats:combated:combated:combating", null, null),
  "citadel": new DFWord("citadel:citadels", null, null, null),
  "city": new DFWord("city:cities", null, null, null),
  "town": new DFWord("town:towns", null, null, null),
  "village": new DFWord("village:villages", null, null, null),
  "manor": new DFWord("manor:manors", null, null, null),
  "mansion": new DFWord("mansion:mansions", null, null, null),
  "palace": new DFWord("palace:palaces", null, null, null),
  "cottage": new DFWord("cottage:cottages", null, null, null),
  "shack": new DFWord("shack:shacks", null, null, null),
  "hut": new DFWord("hut:huts", null, null, null),
  "hovel": new DFWord("hovel:hovels", null, null, null),
  "bastion": new DFWord("bastion:bastions", null, null, null),
  "furnace": new DFWord("furnace:furnaces", null, null, null),
  "anvil": new DFWord("anvil:anvils", null, null, null),
  "bridge": new DFWord("bridge:bridges", "bridge:bridges:bridged:bridged:bridging", null, null),
  "safe": new DFWord("safety:", "save:saves:saved:saved:saving", "safe", null),
  "goad": new DFWord("goad:goads", "goad:goads:goaded:goaded:goading", null, null),
  "machine": new DFWord("machine:machines", null, null, null),
  "flay": new DFWord("flayer:flayers", "flay:flays:flayed:flayed:flaying", "flayed", null),
  "oil": new DFWord("oil:oils", "oil:oils:oiled:oiled:oiling", "oily", null),
  "suicide": new DFWord("suicide:suicides", null, "suicidal", null),
  "marble_ball": new DFWord("marble:marbles", null, null, null),
  "bait": new DFWord("bait:baits", "bait:baits:baited:baited:baiting", null, null),
  "lure": new DFWord("lure:lures", "lure:lures:lured:lured:luring", null, null),
  "reward": new DFWord("reward:rewards", "reward:rewards:rewarded:rewarded:rewarding", null, null),
  "ward": new DFWord("ward:wards", "ward:wards:warded:warded:warding", null, null),
  "price": new DFWord("price:prices", "price:prices:priced:priced:pricing", null, null),
  "prince": new DFWord("prince:princes", null, null, null),
  "princess": new DFWord("princess:princesses", null, null, null),
  "tempt": new DFWord("temptation:", "tempt:tempts:tempted:tempted:tempting", "tempted", null),
  "spurn": new DFWord("spurn:spurns", "spurn:spurns:spurned:spurned:spurning", null, null),
  "scorn": new DFWord("scorn:scorns", "scorn:scorns:scorned:scorned:scorning", null, null),
  "contempt": new DFWord("contempt:", null, "contemptible", null),
  "affection": new DFWord("affection:", null, null, null),
  "compassion": new DFWord("compassion:", null, null, null),
  "sacrifice": new DFWord("sacrifice:sacrifices", "sacrifice:sacrifices:sacrificed:sacrificed:sacrificing", "sacrificial", null),
  "charity": new DFWord("charity:", null, null, null),
  "cherish": new DFWord(null, "cherish:cherishes:cherished:cherished:cherishing", null, null),
  "trample": new DFWord(null, "trample:tramples:trampled:trampled:trampling", "trampled", null),
  "malice": new DFWord("malice:", null, "malicious", null),
  "hatred": new DFWord("hatred:", null, null, null),
  "spite": new DFWord("spite:", "spite:spites:spited:spited:spiting", "spiteful", null),
  "shore": new DFWord("shore:shores", null, null, null),
  "beach": new DFWord("beach:beaches", "beach:beaches:beached:beached:beaching", null, null),
  "coast": new DFWord("coast:coasts", null, "coastal", null),
  "insect": new DFWord("insect:insects", null, null, null),
  "bug": new DFWord("bug:bugs", null, null, null),
  "critter": new DFWord("critter:critters", null, null, null),
  "brute": new DFWord("brute:brutes", "brutalize:brutalizes:brutalized:brutalized:brutalizing", "brutal", null),
  "pet": new DFWord("pet:pets", null, null, null),
  "barbarian": new DFWord("barbarian:barbarians", null, null, null),
  "harsh": new DFWord("harshness:", null, "harsh", null),
  "pristine": new DFWord(null, null, "pristine", null),
  "ferocity": new DFWord("ferocity:", null, "ferocious", null),
  "barbarity": new DFWord("barbarity:", null, "barbarous", null),
  "feral": new DFWord(null, null, "feral", null),
  "fury": new DFWord("fury:", null, "furious", null),
  "ruthless": new DFWord("ruthlessness:", null, "ruthless", null),
  "gore_noun": new DFWord("gore:", null, "gory", null),
  "champion": new DFWord("champion:champions", null, null, null),
  "virtue": new DFWord("virtue:virtues", null, "virtuous", null),
  "scandal": new DFWord("scandal:scandals", null, "scandalous", null),
  "apogee": new DFWord("apogee:apogees", null, null, null),
  "spire": new DFWord("spire:spires", null, null, null),
  "spiral": new DFWord("spiral:spirals", "spiral:spirals:spiraled:spiraled:spiraling", null, null),
  "climax": new DFWord("climax:climaxes", null, "climactic", null),
  "climate": new DFWord("climate:climates", null, null, null),
  "weather": new DFWord("weather:", null, null, null),
  "crest": new DFWord("crest:crests", "crest:crests:crested:crested:cresting", "crested", null),
  "culminate": new DFWord("culmination:", "culminate:culminates:culminated:culminated:culminating", null, null),
  "cusp": new DFWord("cusp:cusps", null, null, null),
  "most": new DFWord(null, null, "most", null),
  "peak": new DFWord("peak:peaks", "peak:peaks:peaked:peaked:peaking", null, null),
  "blunt": new DFWord(null, "blunt:blunts:blunted:blunted:blunting", "blunt", null),
  "roof": new DFWord("roof:roofs", null, null, null),
  "ceiling": new DFWord("ceiling:ceilings", null, null, null),
  "floor": new DFWord("floor:floors", null, null, null),
  "summit": new DFWord("summit:summits", null, null, null),
  "tip": new DFWord("tip:tips", null, null, null),
  "top": new DFWord("top:tops", null, null, null),
  "bottom": new DFWord("bottom:bottoms", null, null, null),
  "side": new DFWord("side:sides", null, null, null),
  "zenith": new DFWord("zenith:zeniths", null, null, null),
  "flank_verb": new DFWord(null, "flank:flanks:flanked:flanked:flanking", null, null),
  "pass_mountain": new DFWord("pass:passes", null, null, null),
  "pass_verb": new DFWord(null, "pass:passes:passed:passed:passing", null, null),
  "passage": new DFWord("passage:passages", null, null, null),
  "corridor": new DFWord("corridor:corridors", null, null, null),
  "hall": new DFWord("hall:halls", null, null, null),
  "gallery": new DFWord("gallery:galleries", null, null, null),
  "galley": new DFWord("galley:galleys", null, null, null),
  "arena": new DFWord("arena:arenas", null, null, null),
  "armory": new DFWord("armory:armories", null, null, null),
  "assemble": new DFWord("assembly:assemblies", "assemble:assembles:assembled:assembled:assembling", null, null),
  "meet": new DFWord("meeting:meetings", "meet:meets:met:met:meeting", null, null),
  "theater": new DFWord("theater:theaters", null, null, null),
  "entrance": new DFWord("entrance:entrances", null, null, null),
  "exit": new DFWord("exit:exits", "exit:exits:exited:exited:exiting", null, null),
  "entry": new DFWord("entry:entries", "enter:enters:entered:entered:entering", null, null),
  "vestibule": new DFWord("vestibule:vestibules", null, null, null),
  "frost": new DFWord("frost:", null, "frosty", null),
  "fever": new DFWord("fever:fevers", null, "feverish", null),
  "humid": new DFWord("humidity:", null, "humid", null),
  "humor": new DFWord("humor:", null, "humorous", null),
  "comedy": new DFWord("comedy:", null, "comedic", null),
  "sizzle": new DFWord(null, "sizzle:sizzles:sizzled:sizzled:sizzling", "sizzling", null),
  "swelter": new DFWord(null, "swelter:swelters:sweltered:sweltered:sweltering", "sweltering", null),
  "warm": new DFWord("warmth:", null, "warm", null),
  "torrid": new DFWord(null, null, "torrid", null),
  "acrid": new DFWord(null, null, "acrid", null),
  "dale": new DFWord("dale:dales", null, null, null),
  "dell": new DFWord("dell:dells", null, null, null),
  "glade": new DFWord("glade:glades", null, null, null),
  "vale": new DFWord("vale:vales", null, null, null),
  "basin": new DFWord("basin:basins", null, null, null),
  "bowl": new DFWord("bowl:bowls", null, null, null),
  "cavity": new DFWord("cavity:cavities", null, null, null),
  "crater": new DFWord("crater:craters", null, "craterous", null),
  "den": new DFWord("den:dens", null, null, null),
  "dent": new DFWord("dent:dents", "dent:dents:dented:dented:denting", "dented", null),
  "depress_sad": new DFWord("depression:", "depress:depresses:depressed:depressed:depressing", "depressed", null),
  "depression_low": new DFWord("depression:depressions", null, null, null),
  "dimple": new DFWord("dimple:dimples", "dimple:dimples:dimpled:dimpled:dimpling", "dimpled", null),
  "dip": new DFWord("dip:dips", "dip:dips:dipped:dipped:dipping", "dipped", null),
  "dish": new DFWord("dish:dishes", null, null, null),
  "excavate": new DFWord("excavation:excavations", "excavate:excavates:excavated:excavated:excavating", "excavated", null),
  "groove": new DFWord("groove:grooves", "groove:grooves:grooved:grooved:grooving", "grooved", null),
  "gulf_sea": new DFWord("gulf:gulfs", null, null, null),
  "gulf_pit": new DFWord("gulf:gulfs", null, null, null),
  "gulf_distance": new DFWord("gulf:gulfs", null, null, null),
  "distance": new DFWord("distance:distances", null, "distant", null),
  "notch": new DFWord("notch:notches", "notch:notches:notched:notched:notching", "notched", null),
  "sag": new DFWord(null, "sag:sags:sagged:sagged:sagging", "sagging", null),
  "scoop": new DFWord("scoop:scoops", "scoop:scoops:scooped:scooped:scooping", null, null),
  "socket": new DFWord("socket:sockets", "socket:sockets:socketed:socketed:socketing", "socketed", null),
  "trough": new DFWord("trough:troughs", null, null, null),
  "valley": new DFWord("valley:valleys", null, null, null),
  "fence": new DFWord("fence:fences", null, "fenced", null),
  "barricade": new DFWord("barricade:barricades", "barricade:barricades:barricaded:barricaded:barricading", "barricaded", null),
  "blockade": new DFWord("blockade:blockades", "blockade:blockades:blockaded:blockaded:blockading", "blockaded", null),
  "board_plank": new DFWord("board:boards", null, null, null),
  "board_get_on": new DFWord(null, "board:boards:boarded:boarded:boarding", null, null),
  "plank": new DFWord("plank:planks", null, null, null),
  "defend": new DFWord("defender:defenders", "defend:defends:defended:defended:defending", "defended", null),
  "defense": new DFWord("defense:defenses", null, "defensive", null),
  "dike": new DFWord("dike:dikes", null, null, null),
  "palisade": new DFWord("palisade:palisades", null, null, null),
  "post": new DFWord("post:posts", null, null, null),
  "rampart": new DFWord("rampart:ramparts", null, null, null),
  "stake": new DFWord("stake:stakes", null, null, null),
  "stop": new DFWord("stop:stops", "stop:stops:stopped:stopped:stopping", null, null),
  "stockade": new DFWord("stockade:stockades", null, null, null),
  "abyss": new DFWord("abyss:abysses", null, "abyssal", null),
  "chasm": new DFWord("chasm:chasms", null, null, null),
  "crevice": new DFWord("crevice:crevices", null, null, null),
  "rift": new DFWord("rift:rifts", null, null, null),
  "fissure": new DFWord("fissure:fissures", null, null, null),
  "under": new DFWord(null, null, null, "under"),
  "abysmal": new DFWord(null, null, "abysmal", null),
  "submerge": new DFWord(null, "submerge:submerges:submerged:submerged:submerging", "submerged", null),
  "cyclone": new DFWord("cyclone:cyclones", null, null, null),
  "typhoon": new DFWord("typhoon:typhoons", null, null, null),
  "hurricane": new DFWord("hurricane:hurricanes", null, null, null),
  "gale": new DFWord("gale:gales", null, null, null),
  "tornado": new DFWord("tornado:tornados", null, null, null),
  "hail_greet": new DFWord(null, "hail:hails:hailed:hailed:hailing", null, null),
  "hail_ice": new DFWord("hail:", "hail:hails:hailed:hailed:hailing", null, null),
  "hale": new DFWord(null, null, "hale", null),
  "robust": new DFWord("robustness:", null, "robust", null),
  "hardy": new DFWord("hardiness:", null, "hardy", null),
  "vigor": new DFWord("vigor:", null, "vigorous", null),
  "air": new DFWord("air:", null, null, null),
  "splash": new DFWord("splash:splashes", "splash:splashes:splashed:splashed:splashing", null, null),
  "dabble": new DFWord("dabbler:dabblers", "dabble:dabbles:dabbled:dabbled:dabbling", "dabbling", null),
  "douse": new DFWord(null, "douse:douses:doused:doused:dousing", "doused", null),
  "drench": new DFWord(null, "drench:drenches:drenched:drenched:drenching", "drenched", null),
  "moist": new DFWord("moistness:", "moisten:moistens:moistened:moistened:moistening", "moist", null),
  "plunge": new DFWord("plunge:plunges", "plunge:plunges:plunged:plunged:plunging", null, null),
  "shower": new DFWord("shower:showers", "shower:showers:showered:showered:showering", null, null),
  "slop": new DFWord("slop:", "slop:slops:slopped:slopped:slopping", "sloppy", null),
  "slosh": new DFWord(null, "slosh:sloshes:sloshed:sloshed:sloshing", null, null),
  "soak": new DFWord(null, "soak:soaks:soaked:soaked:soaking", "soaked", null),
  "spatter": new DFWord(null, "spatter:spatters:spattered:spattered:spattering", "spattered", null),
  "splatter": new DFWord(null, "splatter:splatters:splattered:splattered:splattering", "splattered", null),
  "spray": new DFWord("spray:sprays", "spray:sprays:sprayed:sprayed:spraying", "sprayed", null),
  "spread": new DFWord(null, "spread:spreads:spread:spread:spreading", "spread", null),
  "sprinkle": new DFWord(null, "sprinkle:sprinkles:sprinkled:sprinkled:sprinkling", null, null),
  "twinkle": new DFWord("twinkle:twinkles", "twinkle:twinkles:twinkled:twinkled:twinkling", "twinkling", null),
  "squirt": new DFWord(null, "squirt:squirts:squirted:squirted:squirting", null, null),
  "wad": new DFWord("wad:wads", null, null, null),
  "wade": new DFWord(null, "wade:wades:waded:waded:wading", null, null),
  "wet": new DFWord(null, "wet:wets:wet:wet:wetting", "wet", null),
  "fade": new DFWord(null, "fade:fades:faded:faded:fading", "faded", null),
  "colorless": new DFWord(null, null, "colorless", null),
  "blanch": new DFWord(null, "blanch:blanches:blanched:blanched:blanching", null, null),
  "bleach": new DFWord(null, "bleach:bleaches:bleached:bleached:bleaching", null, null),
  "blench": new DFWord(null, "blench:blenches:blenched:blenched:blenching", null, null),
  "flinch": new DFWord(null, "flinch:flinches:flinched:flinched:flinching", null, null),
  "appear": new DFWord("appearance:appearances", "appear:appears:appeared:appeared:appearing", null, null),
  "disappear": new DFWord("disappearance:disappearances", "disappear:disappears:disappeared:disappeared:disappearing", null, null),
  "dissolve": new DFWord(null, "dissolve:dissolves:dissolved:dissolved:dissolving", null, null),
  "dull": new DFWord(null, "dull:dulls:dulled:dulled:dulling", "dull", null),
  "evaporate": new DFWord("evaporation:", "evaporate:evaporates:evaporated:evaporated:evaporating", "evaporated", null),
  "luster": new DFWord("luster:lusters", null, "lustrous", null),
  "tone": new DFWord("tone:tones", "tone:tones:toned:toned:toning", null, null),
  "vanish": new DFWord("vanishment:", "vanish:vanishes:vanished:vanished:vanishing", "vanished", null),
  "wash": new DFWord(null, "wash:washes:washed:washed:washing", "washed", null),
  "abate": new DFWord("abatement:", "abate:abates:abated:abated:abating", "abated", null),
  "decline": new DFWord("decline:declines", "decline:declines:declined:declined:declining", "declining", null),
  "deteriorate": new DFWord("deterioration:", "deteriorate:deteriorates:deteriorated:deteriorated:deteriorating", "deteriorated", null),
  "diminish": new DFWord("diminishment:", "diminish:diminishes:diminished:diminished:diminishing", "diminished", null),
  "disperse": new DFWord("dispersal:", "disperse:disperses:dispersed:dispersed:dispersing", "dispersed", null),
  "droop": new DFWord("droopiness:", "droop:droops:drooped:drooped:drooping", "droopy", null),
  "dwindle": new DFWord(null, "dwindle:dwindles:dwindled:dwindled:dwindling", "dwindling", null),
  "kindle": new DFWord("kindling:", "kindle:kindles:kindled:kindled:kindling", "kindled", null),
  "stoke": new DFWord("stoker:stokers", "stoke:stokes:stoked:stoked:stoking", "stoked", null),
  "poke": new DFWord("poker:pokers", "poke:pokes:poked:poked:poking", null, null),
  "stir": new DFWord(null, "stir:stirs:stirred:stirred:stirring", "stirred", null),
  "feed": new DFWord("feed:", "feed:feeds:fed:fed:feeding", "fed", null),
  "fail": new DFWord("failure:failures", "fail:fails:failed:failed:failing", "failed", null),
  "languish": new DFWord(null, "languish:languishes:languished:languished:languishing", "languishing", null),
  "lessen": new DFWord(null, "lessen:lessens:lessened:lessened:lessening", "lessened", null),
  "lesson": new DFWord("lesson:lessons", null, null, null),
  "teach": new DFWord("teacher:teachers", "teach:teaches:taught:taught:teaching", null, null),
  "mastery": new DFWord("mastery:", null, null, null),
  "perish": new DFWord("perishment:", "perish:perishes:perished:perished:perishing", null, null),
  "shrivel": new DFWord(null, "shrivel:shrivels:shriveled:shriveled:shriveling", "shriveled", null),
  "sink": new DFWord(null, "sink:sinks:sank:sunk:sinking", "sunken", null),
  "taper": new DFWord(null, "taper:tapers:tapered:tapered:tapering", "tapered", null),
  "thin": new DFWord(null, "thin:thins:thinned:thinned:thinning", "thin", null),
  "tire": new DFWord("tiredness:", "tire:tires:tired:tired:tiring", "tired", null),
  "wane": new DFWord("wane:wanes", "wane:wanes:waned:waned:waning", "waning", null),
  "weak": new DFWord("weakness:weaknesses", "weaken:weakens:weakened:weakened:weakening", "weak", null),
  "wilt": new DFWord("wilt:wilts", "wilt:wilts:wilted:wilted:wilting", "wilted", null),
  "wither": new DFWord(null, "wither:withers:withered:withered:withering", "withered", null),
  "honor": new DFWord("honor:", "honor:honors:honored:honored:honoring", "honorable", null),
  "adore": new DFWord("adoration:", "adore:adores:adored:adored:adoring", "adorable", null),
  "adulate": new DFWord("adulation:", null, null, null),
  "celebrate": new DFWord("celebration:celebrations", "celebrate:celebrates:celebrated:celebrated:celebrating", "celebrated", null),
  "confident": new DFWord("confidence:", null, "confident", null),
  "deference": new DFWord("deference:", null, "deferent", null),
  "deity": new DFWord("deity:deities", null, null, null),
  "distinct": new DFWord("distinction:distinctions", "distinguish:distinguishes:distinguished:distinguished:distinguishing", "distinct", null),
  "elevate": new DFWord("elevation:", "elevate:elevates:elevated:elevated:elevating", "elevated", null),
  "esteem": new DFWord("esteem:", "esteem:esteems:esteemed:esteemed:esteeming", "esteemed", null),
  "exalt": new DFWord("exaltation:", "exalt:exalts:exalted:exalted:exalting", "exalted", null),
  "fame": new DFWord("fame:", null, "famous", null),
  "fealty": new DFWord("fealty:fealties", null, null, null),
  "homage": new DFWord("homage:homages", null, null, null),
  "mortal": new DFWord("mortal:mortals", null, "mortal", null),
  "immortal": new DFWord("immortal:immortals", null, "immortal", null),
  "laud": new DFWord(null, "laud:lauds:lauded:lauded:lauding", "lauded", null),
  "obeisance": new DFWord("obeisance:", null, "obeisant", null),
  "praise": new DFWord("praise:praises", "praise:praises:praised:praised:praising", "praised", null),
  "prestige": new DFWord("prestige:", null, "prestigious", null),
  "renown": new DFWord("renown:", null, "renowned", null),
  "reputation": new DFWord("reputation:reputations", "repute:reputes:reputed:reputed:reputing", "reputed", null),
  "tribute": new DFWord("tribute:tributes", null, null, null),
  "worship": new DFWord("worship:", "worship:worships:worshipped:worshipped:worshipping", "worshipful", null),
  "chastity": new DFWord("chastity:", null, "chaste", null),
  "courage": new DFWord("courage:", null, "courageous", null),
  "decent": new DFWord("decency:", null, "decent", null),
  "good": new DFWord("good:", null, "good", null),
  "innocent": new DFWord("innocence:", null, "innocent", null),
  "modest": new DFWord("modesty:", null, "modest", null),
  "principle": new DFWord("principle:principles", null, null, null),
  "pure": new DFWord("purity:", "purify:purifies:purified:purified:purifying", "pure", null),
  "righteousness": new DFWord("righteousness:", null, null, null),
  "truth": new DFWord("truth:truths", null, "true", null),
  "truthful": new DFWord(null, null, "truthful", null),
  "virgin": new DFWord("virgin:virgins", null, "virginal", null),
  "virginity": new DFWord("virginity:", null, null, null),
  "hold": new DFWord("hold:holds", "hold:holds:held:held:holding", "held", null),
  "scald": new DFWord(null, "scald:scalds:scalded:scalded:scalding", "scalded", null),
  "scale_verb": new DFWord(null, "scale:scales:scaled:scaled:scaling", null, null),
  "scale_skin": new DFWord("scale:scales", null, "scaly", null),
  "clan": new DFWord("clan:clans", null, null, null),
  "labyrinth": new DFWord("labyrinth:labyrinths", null, "labyrinthine", null),
  "maze": new DFWord("maze:mazes", null, null, null),
  "web": new DFWord("web:webs", "web:webs:webbed:webbed:webbing", null, null),
  "crowd": new DFWord("crowd:crowds", "crowd:crowds:crowded:crowded:crowding", "crowded", null),
  "family": new DFWord("family:families", null, "familial", null),
  "kin": new DFWord("kin:", null, null, null),
  "mob": new DFWord("mob:mobs", "mob:mobs:mobbed:mobbed:mobbing", null, null),
  "organ": new DFWord("organ:organs", null, null, null),
  "organize": new DFWord("organization:organizations", "organize:organizes:organized:organized:organizing", "organized", null),
  "mesh": new DFWord("mesh:meshes", null, null, null),
  "tangle": new DFWord("tangle:tangles", "tangle:tangles:tangled:tangled:tangling", "tangled", null),
  "entangle": new DFWord("entanglement:", "entangle:entangles:entangled:entangled:entangling", "entangled", null),
  "puzzle": new DFWord("puzzle:puzzles", "puzzle:puzzles:puzzled:puzzled:puzzling", "puzzling", null),
  "perplex": new DFWord(null, "perplex:perplexes:perplexed:perplexed:perplexing", "perplexing", null),
  "quandary": new DFWord("quandary:quandaries", null, null, null),
  "combine": new DFWord("combination:combinations", "combine:combines:combined:combined:combining", "combined", null),
  "flicker": new DFWord("flicker:flickers", "flicker:flickers:flickered:flickered:flickering", "flickering", null),
  "brand": new DFWord("brand:brands", "brand:brands:branded:branded:branding", "branded", null),
  "singe": new DFWord(null, "singe:singes:singed:singed:singing", "singed", null),
  "sear": new DFWord(null, "sear:sears:seared:seared:searing", "searing", null),
  "scorch": new DFWord(null, "scorch:scorches:scorched:scorched:scorching", "scorching", null),
  "roast": new DFWord(null, "roast:roasts:roasted:roasted:roasting", "roasted", null),
  "parch": new DFWord(null, "parch:parches:parched:parched:parching", "parched", null),
  "ignite": new DFWord("ignition:", "ignite:ignites:ignited:ignited:igniting", "ignited", null),
  "incinerate": new DFWord("incineration:", "incinerate:incinerates:incinerated:incinerated:incinerating", "incinerated", null),
  "glow": new DFWord("glow:glows", "glow:glows:glowed:glowed:glowing", "glowing", null),
  "cremate": new DFWord("cremation:cremations", "cremate:cremates:cremated:cremated:cremating", "cremated", null),
  "faction": new DFWord("faction:factions", null, "factional", null),
  "crew": new DFWord("crew:crews", "crew:crews:crewed:crewed:crewing", "crewed", null),
  "vessel": new DFWord("vessel:vessels", null, null, null),
  "league_group": new DFWord("league:leagues", null, null, null),
  "cooperate": new DFWord("cooperation:", "cooperate:cooperates:cooperated:cooperated:cooperating", "cooperative", null),
  "group": new DFWord("group:groups", "group:groups:grouped:grouped:grouping", null, null),
  "gang": new DFWord("gang:gangs", null, null, null),
  "fellowship": new DFWord("fellowship:fellowships", null, null, null),
  "partner": new DFWord("partner:partners", "partner:partners:partnered:partnered:partnering", null, null),
  "riddle": new DFWord("riddle:riddles", "riddle:riddles:riddled:riddled:riddling", null, null),
  "rout": new DFWord("rout:routs", "rout:routs:routed:routed:routing", "routed", null),
  "scrape": new DFWord("scrape:scrapes", "scrape:scrapes:scraped:scraped:scraping", "scraped", null),
  "scrap": new DFWord("scrap:scraps", null, null, null),
  "scour": new DFWord(null, "scour:scours:scoured:scoured:scouring", "scoured", null),
  "scrub": new DFWord(null, "scrub:scrubs:scrubbed:scrubbed:scrubbing", "scrubbed", null),
  "shake": new DFWord(null, "shake:shakes:shook:shaken:shaking", "shaken", null),
  "soap": new DFWord("soap:soaps", null, "soapy", null),
  "sponge": new DFWord("sponge:sponges", null, "spongy", null),
  "sweep": new DFWord(null, "sweep:sweeps:swept:swept:sweeping", null, null),
  "whisker": new DFWord("whisker:whiskers", null, "whiskered", null),
  "fasten": new DFWord(null, "fasten:fastens:fastened:fastened:fastening", null, null),
  "winnow": new DFWord("winnower:winnowers", "winnow:winnows:winnowed:winnowed:winnowing", null, null),
  "wipe": new DFWord(null, "wipe:wipes:wiped:wiped:wiping", null, null),
  "clench": new DFWord(null, "clench:clenches:clenched:clenched:clenching", null, null),
  "clinch": new DFWord("clinch:clinches", "clinch:clinches:clinched:clinched:clinching", null, null),
  "clout": new DFWord("clout:clouts", "clout:clouts:clouted:clouted:clouting", null, null),
  "clutch": new DFWord("clutch:clutches", "clutch:clutches:clutched:clutched:clutching", null, null),
  "dominate": new DFWord("domination:", "dominate:dominates:dominated:dominated:dominating", "dominant", null),
  "dominion": new DFWord("dominion:dominions", null, null, null),
  "grasp": new DFWord("grasp:grasps", "grasp:grasps:grasped:grasped:grasping", "grasping", null),
  "influence": new DFWord("influence:influences", "influence:influences:influenced:influenced:influencing", "influential", null),
  "own": new DFWord("owner:owners", "own:owns:owned:owned:owning", null, null),
  "ownership": new DFWord("ownership:", null, null, null),
  "tenacity": new DFWord("tenacity:", null, "tenacious", null),
  "bind": new DFWord(null, "bind:binds:bound:bound:binding", null, null),
  "carry": new DFWord(null, "carry:carries:carried:carried:carrying", null, null),
  "catch": new DFWord("catch:catches", "catch:catches:caught:caught:catching", null, null),
  "confine": new DFWord("confinement:", "confine:confines:confined:confined:confining", "confining", null),
  "contain": new DFWord("container:containers", "contain:contains:contained:contained:containing", "contained", null),
  "cradle": new DFWord("cradle:cradles", "cradle:cradles:cradled:cradled:cradling", "cradled", null),
  "embrace": new DFWord("embrace:embraces", "embrace:embraces:embraced:embraced:embracing", "embraced", null),
  "pool": new DFWord("pool:pools", null, null, null),
  "society": new DFWord("society:societies", null, null, null),
  "circle": new DFWord("circle:circles", "circle:circles:circled:circled:circling", "circular", null),
  "square": new DFWord("square:squares", null, "square", null),
  "triangle": new DFWord("triangle:triangles", null, "triangular", null),
  "connect": new DFWord("connection:connections", "connect:connects:connected:connected:connecting", "connected", null),
  "merge": new DFWord(null, "merge:merges:merged:merged:merging", "merged", null),
  "confuse": new DFWord("confusion:", "confuse:confuses:confused:confused:confusing", "confusing", null),
  "complex": new DFWord("complexity:", null, "complex", null),
  "meander": new DFWord(null, "meander:meanders:meandered:meandered:meandering", "meandering", null),
  "muddle": new DFWord(null, "muddle:muddles:muddled:muddled:muddling", "muddled", null),
  "bewilder": new DFWord("bewilderment:", "bewilder:bewilders:bewildered:bewildered:bewildering", "bewildering", null),
  "morass": new DFWord("morass:morasses", null, null, null),
  "problem": new DFWord("problem:problems", null, "problematic", null),
  "intricate": new DFWord("intricacy:intricacies", null, "intricate", null),
  "flush": new DFWord(null, "flush:flushes:flushed:flushed:flushing", null, null),
  "mop": new DFWord("mop:mops", "mop:mops:mopped:mopped:mopping", null, null),
  "polish": new DFWord("polish:", "polish:polishes:polished:polished:polishing", "polished", null),
  "purge": new DFWord("purge:purges", "purge:purges:purged:purged:purging", "purged", null),
  "rasp": new DFWord("rasp:rasps", "rasp:rasps:rasped:rasped:rasping", null, null),
  "rinse": new DFWord(null, "rinse:rinses:rinsed:rinsed:rinsing", null, null),
  "band_object": new DFWord("band:bands", null, "banded", null),
  "band_group": new DFWord("band:bands", null, null, null),
  "tall": new DFWord(null, null, "tall", null),
  "short": new DFWord(null, null, "short", null),
  "bulbous": new DFWord(null, null, "bulbous", null),
  "bulb": new DFWord("bulb:bulbs", null, null, null),
  "ally": new DFWord("ally:allies", "ally:allies:allied:allied:allying", "allied", null),
  "alliance": new DFWord("alliance:alliances", null, null, null),
  "allegiance": new DFWord("allegiance:allegiances", null, null, null),
  "coalition": new DFWord("coalition:coalitions", null, null, null),
  "coal": new DFWord("coal:", null, null, null),
  "enjoy": new DFWord("enjoyment:", "enjoy:enjoys:enjoyed:enjoyed:enjoying", "enjoyable", null),
  "fondle": new DFWord(null, "fondle:fondles:fondled:fondled:fondling", "fondled", null),
  "handle_object": new DFWord("handle:handles", null, null, null),
  "handle_verb": new DFWord(null, "handle:handles:handled:handled:handling", null, null),
  "imprison": new DFWord("imprisonment:", "imprison:imprisons:imprisoned:imprisoned:imprisoning", "imprisoned", null),
  "nourish": new DFWord("nourishment:", "nourish:nourishes:nourished:nourished:nourishing", "nourishing", null),
  "squeeze": new DFWord(null, "squeeze:squeezes:squeezed:squeezed:squeezing", "squeezing", null),
  "trammel": new DFWord("trammel:trammels", "trammel:trammels:trammeled:trammeled:trammeling", null, null),
  "vice": new DFWord("vice:", null, null, null),
  "vise": new DFWord("vise:vises", "vise:vises:vised:vised:vising", null, null),
  "defect_fault": new DFWord("defect:defects", null, "defective", null),
  "perfect": new DFWord("perfection:", "perfect:perfects:perfected:perfected:perfecting", "perfect", null),
  "gem": new DFWord("gem:gems", null, null, null),
  "jewel": new DFWord("jewel:jewels", "bejewel:bejewels:bejeweled:bejeweled:bejeweling", "bejeweled", null),
  "wield": new DFWord(null, "wield:wields:wielded:wielded:wielding", null, null),
  "wring": new DFWord(null, "wring:wrings:wrung:wrung:wringing", null, null),
  "conjunction": new DFWord("conjunction:conjunctions", null, null, null),
  "blot": new DFWord("blot:blots", "blot:blots:blotted:blotted:blotting", "blotted", null),
  "blow": new DFWord(null, "blow:blows:blew:blown:blowing", "blowing", null),
  "brush": new DFWord("brush:brushes", "brush:brushes:brushed:brushed:brushing", "brushed", null),
  "clarify": new DFWord("clarity:", "clarify:clarifies:clarified:clarified:clarifying", null, null),
  "cleanse": new DFWord(null, "cleanse:cleanses:cleansed:cleansed:cleansing", null, null),
  "dredge": new DFWord(null, "dredge:dredges:dredged:dredged:dredging", null, null),
  "erase": new DFWord("erasure:", "erase:erases:erased:erased:erasing", "erased", null),
  "morsel": new DFWord("morsel:morsels", null, null, null),
  "mongrel": new DFWord("mongrel:mongrels", null, null, null),
  "tender": new DFWord("tenderness:", null, "tender", null),
  "urge": new DFWord("urge:urges", "urge:urges:urged:urged:urging", null, null),
  "mechanism": new DFWord("mechanism:mechanisms", null, "mechanical", null),
  "smear": new DFWord("smear:smears", "smear:smears:smeared:smeared:smearing", "smeared", null),
  "tattoo": new DFWord("tattoo:tattoos", "tattoo:tattoos:tattooed:tattooed:tattooing", "tattooed", null),
  "weather_verb": new DFWord("weathering:", "weather:weathers:weathered:weathered:weathering", "weathered", null),
  "crux": new DFWord("crux:cruxes", null, null, null),
  "peek": new DFWord("peek:peeks", "peek:peeks:peeked:peeked:peeking", null, null),
  "sneak": new DFWord(null, "sneak:sneaks:sneaked:sneaked:sneaking", null, null),
  "attic": new DFWord("attic:attics", null, null, null),
  "basement": new DFWord("basement:basements", null, null, null),
  "dearth": new DFWord("dearth:", null, null, null),
  "apex": new DFWord("apex:apexes", null, null, null),
  "nadir": new DFWord("nadir:nadirs", null, null, null),
  "tunnel": new DFWord("tunnel:tunnels", "tunnel:tunnels:tunneled:tunneled:tunneling", null, null),
  "entrance_verb": new DFWord(null, "entrance:entrances:entranced:entranced:entrancing", "entrancing", null),
  "delight": new DFWord("delight:delights", "delight:delights:delighted:delighted:delighting", "delightful", null),
  "joy": new DFWord("joy:joys", null, "joyous", null),
  "frigid": new DFWord("frigidity:", null, "frigid", null),
  "ankle": new DFWord("ankle:ankles", null, null, null),
  "elbow": new DFWord("elbow:elbows", null, null, null),
  "passion": new DFWord("passion:passions", null, "passionate", null),
  "ardent": new DFWord(null, null, "ardent", null),
  "stream": new DFWord("stream:streams", null, null, null),
  "bowel_gut": new DFWord("bowel:bowels", null, null, null),
  "bowel_interior": new DFWord("bowel:bowels", null, null, null),
  "drill_bore": new DFWord(null, "drill:drills:drilled:drilled:drilling", null, null),
  "drill_routine": new DFWord("drill:drills", "drill:drills:drilled:drilled:drilling", null, null),
  "bore_drill": new DFWord(null, "bore:bores:bored:bored:boring", null, null),
  "boredom": new DFWord("boredom:", "bore:bores:bored:bored:boring", "boring", null),
  "fleck": new DFWord("fleck:flecks", null, "flecked", null),
  "spot": new DFWord("spot:spots", null, "spotted", null),
  "dot": new DFWord("dot:dots", null, null, null),
  "create": new DFWord("creation:creations", "create:creates:created:created:creating", "creative", null),
  "poem": new DFWord("poem:poems", null, null, null),
  "poetry": new DFWord("poetry:", null, "poetic", null),
  "poet": new DFWord("poet:poets", null, null, null),
  "scribe": new DFWord("scribe:scribes", "scribe:scribes:scribed:scribed:scribing", null, null),
  "monk": new DFWord("monk:monks", null, null, null),
  "hermit": new DFWord("hermit:hermits", null, "hermitic", null),
  "recluse": new DFWord("recluse:recluses", null, "reclusive", null),
  "name": new DFWord("name:names", "name:names:named:named:naming", "named", null),
  "language": new DFWord("language:languages", null, null, null),
  "phrase": new DFWord("phrase:phrases", "phrase:phrases:phrased:phrased:phrasing", null, null),
  "rhyme": new DFWord("rhyme:rhymes", "rhyme:rhymes:rhymed:rhymed:rhyming", "rhyming", null),
  "rhythm": new DFWord("rhythm:rhythms", null, "rhythmic", null),
  "verse": new DFWord("verse:verses", null, null, null),
  "drum_verb": new DFWord(null, "drum:drums:drummed:drummed:drumming", null, null),
  "creature": new DFWord("creature:creatures", null, null, null),
  "snail": new DFWord("snail:snails", null, null, null),
  "vine": new DFWord("vine:vines", null, null, null),
  "squash": new DFWord(null, "squash:squashes:squashed:squashed:squashing", "squashed", null),
  "never": new DFWord(null, null, null, "never"),
  "dwell": new DFWord("dweller:dwellers", "dwell:dwells:dwelled:dwelled:dwelling", null, null),
  "habit": new DFWord("habit:habits", null, "habitual", null),
  "chew": new DFWord(null, "chew:chews:chewed:chewed:chewing", null, null),
  "swallow": new DFWord("swallow:swallows", "swallow:swallows:swallowed:swallowed:swallowing", null, null),
  "disembowel": new DFWord("disemboweler:disembowelers", "disembowel:disembowels:disemboweled:disemboweled:disemboweling", "disemboweled", null),
  "eviscerate": new DFWord("eviscerator:eviscerators", "eviscerate:eviscerates:eviscerated:eviscerated:eviscerating", "eviscerated", null),
  "rapid": new DFWord("rapidity:", null, "rapid", null),
  "medicine": new DFWord("medicine:", null, null, null),
  "heal": new DFWord("healer:healers", "heal:heals:healed:healed:healing", "healing", null),
  "sell": new DFWord(null, "sell:sells:sold:sold:selling", null, null),
  "market": new DFWord("market:markets", null, null, null),
  "slow": new DFWord(null, "slow:slows:slowed:slowed:slowing", "slow", null),
  "mire": new DFWord("mire:mires", "mire:mires:mired:mired:miring", "mired", null),
  "admire": new DFWord("admirer:admirers", "admire:admires:admired:admired:admiring", "admired", null),
  "dirge": new DFWord("dirge:dirges", null, null, null),
  "lament": new DFWord("lamentation:", "lament:laments:lamented:lamented:lamenting", "lamented", null),
  "funeral": new DFWord("funeral:funerals", null, null, null),
  "quest": new DFWord("quest:quests", "quest:quests:quested:quested:questing", null, null),
  "adventure": new DFWord("adventure:adventures", "adventure:adventures:adventured:adventured:adventuring", "adventurous", null),
  "mourn": new DFWord(null, "mourn:mourns:mourned:mourned:mourning", "mournful", null),
  "morning": new DFWord("morning:mornings", null, null, null),
  "bulwark": new DFWord("bulwark:bulwarks", null, null, null),
  "cosmos": new DFWord("cosmos:", null, null, null),
  "anguish": new DFWord("anguish:", "anguish:anguishes:anguished:anguished:anguishing", "anguished", null),
  "taut": new DFWord(null, null, "taut", null),
  "deify": new DFWord("deification:", "deify:deifies:deified:deified:deifying", "deified", null),
  "infamy": new DFWord("infamy:", null, "infamous", null),
  "mortality": new DFWord("mortality:", null, null, null),
  "immortality": new DFWord("immortality:", null, null, null),
  "mortify": new DFWord("mortification:", "mortify:mortifies:mortified:mortified:mortifying", "mortified", null),
  "obey": new DFWord(null, "obey:obeys:obeyed:obeyed:obeying", null, null),
  "worshipper": new DFWord("worshipper:worshippers", null, null, null),
  "riddle_holes": new DFWord(null, "riddle:riddles:riddled:riddled:riddling", "riddled", null),
  "dwelling": new DFWord("dwelling:dwellings", null, null, null),
  "disembowelment": new DFWord("disembowelment:", null, null, null),
  "evisceration": new DFWord("evisceration:", null, null, null),
  "healing": new DFWord("healing:", null, null, null),
  "admiration": new DFWord("admiration:", null, null, null),
  "conflict": new DFWord("conflict:conflicts", null, null, null),
  "attack": new DFWord("attack:attacks", "attack:attacks:attacked:attacked:attacking", null, null),
  "onslaught": new DFWord("onslaught:onslaughts", null, null, null),
  "tree": new DFWord("tree:trees", null, null, null),
  "amber": new DFWord("amber:ambers", null, "amber", null),
  "amethyst": new DFWord("amethyst:amethysts", null, "amethyst", null),
  "aqua": new DFWord(null, null, "aqua", null),
  "aquamarine": new DFWord("aquamarine:aquamarines", null, "aquamarine", null),
  "gray": new DFWord("grayness:graynesses", null, "gray", null),
  "auburn": new DFWord(null, null, "auburn", null),
  "azure": new DFWord(null, null, "azure", null),
  "beige": new DFWord(null, null, "beige", null),
  "brass": new DFWord("brass:", null, "brass", null),
  "brown": new DFWord(null, null, "brown", null),
  "buff": new DFWord(null, null, "buff", null),
  "sienna": new DFWord(null, null, "sienna", null),
  "umber": new DFWord(null, null, "umber", null),
  "cardinal_color": new DFWord(null, null, "cardinal", null),
  "carmine": new DFWord(null, null, "carmine", null),
  "cerulean": new DFWord(null, null, "cerulean", null),
  "charcoal": new DFWord("charcoal:", null, "charcoal", null),
  "chartreuse": new DFWord(null, null, "chartreuse", null),
  "chestnut": new DFWord("chestnut:chestnuts", null, "chestnut", null),
  "chocolate": new DFWord("chocolate:chocolates", null, "chocolate", null),
  "cinnamon": new DFWord("cinnamon:", null, "cinnamon", null),
  "cobalt": new DFWord("cobalt:", null, "cobalt", null),
  "indigo": new DFWord(null, null, "indigo", null),
  "olive": new DFWord("olive:olives", null, "olive", null),
  "pink": new DFWord(null, null, "pink", null),
  "scarlet": new DFWord(null, null, "scarlet", null),
  "tan": new DFWord(null, null, "tan", null),
  "ecru": new DFWord(null, null, "ecru", null),
  "emerald": new DFWord("emerald:emeralds", null, "emerald", null),
  "flax": new DFWord("flax:", null, "flaxen", null),
  "fuchsia": new DFWord(null, null, "fuchsia", null),
  "goldenrod": new DFWord(null, null, "goldenrod", null),
  "heliotrope": new DFWord(null, null, "heliotrope", null),
  "lavender": new DFWord(null, null, "lavender", null),
  "blush": new DFWord("blush:blushes", "blush:blushes:blushed:blushed:blushing", null, null),
  "lemon": new DFWord("lemon:lemons", null, null, null),
  "lilac": new DFWord("lilac:lilacs", null, null, null),
  "lime": new DFWord("lime:limes", null, null, null),
  "mahogany": new DFWord(null, null, "mahogany", null),
  "maroon_color": new DFWord(null, null, "maroon", null),
  "mauve": new DFWord(null, null, "mauve", null),
  "taupe": new DFWord(null, null, "taupe", null),
  "mint": new DFWord(null, null, "mint", null),
  "moss": new DFWord("moss:mosses", null, null, null),
  "ochre": new DFWord(null, null, "ochre", null),
  "orange": new DFWord("orange:oranges", null, null, null),
  "periwinkle": new DFWord("periwinkle:periwinkles", null, null, null),
  "plum": new DFWord("plum:plums", null, null, null),
  "puce": new DFWord(null, null, "puce", null),
  "pumpkin": new DFWord("pumpkin:pumpkins", null, null, null),
  "russet": new DFWord(null, null, "russet", null),
  "saffron": new DFWord("saffron:", null, "saffron", null),
  "sepia": new DFWord(null, null, "sepia", null),
  "teal": new DFWord(null, null, "teal", null),
  "turquoise": new DFWord("turquoise:turquoises", null, "turquoise", null),
  "vermilion": new DFWord(null, null, "vermilion", null),
  "path": new DFWord("path:paths", null, null, null),
  "threat": new DFWord("threat:threats", "threaten:threatens:threatened:threatened:threatening", null, null),
  "warning": new DFWord("warning:warnings", "warn:warns:warned:warned:warning", null, null),
  "fate": new DFWord("fate:fates", null, "fated", null),
  "destiny": new DFWord("destiny:destinies", null, "destined", null),
  "horror": new DFWord("horror:horrors", null, null, null),
  "little": new DFWord(null, null, "little", null),
  "early": new DFWord(null, null, "early", null),
  "late": new DFWord(null, null, "late", null),
  "life": new DFWord("life:lives", "live:lives:lived:lived:living", "living", null),
  "cheese": new DFWord("cheese:cheeses", null, null, null),
  "figure_object": new DFWord("figure:figures", null, null, null),
  "hope": new DFWord("hope:hopes", "hope:hopes:hoped:hoped:hoping", "hopeful", null),
  "body": new DFWord("body:bodies", null, null, null),
  "walk": new DFWord("walk:walks", "walk:walks:walked:walked:walking", null, null),
  "hour": new DFWord("hour:hours", null, null, null),
  "sit": new DFWord(null, "sit:sits:sat:sat:sitting", null, null),
  "moment": new DFWord("moment:moments", null, null, null),
  "beauty": new DFWord("beauty:", null, "beautiful", null),
  "judge": new DFWord("judge:judges", "judge:judges:judged:judged:judging", null, null),
  "wish": new DFWord("wish:wishes", "wish:wishes:wished:wished:wishing", null, null),
  "respect": new DFWord("respect:", "respect:respects:respected:respected:respecting", "respectful", null),
  "mark": new DFWord("mark:marks", "mark:marks:marked:marked:marking", null, null),
  "heavy": new DFWord("heaviness:", null, "heavy", null),
  "trial": new DFWord("trial:trials", null, null, null),
  "practice": new DFWord("practice:", "practice:practices:practiced:practiced:practicing", null, null),
  "end": new DFWord("end:ends", "end:ends:ended:ended:ending", null, null),
  "start": new DFWord("start:starts", "start:starts:started:started:starting", null, null),
  "begin": new DFWord("beginning:beginnings", "begin:begins:began:begun:beginning", null, null),
  "birth": new DFWord("birth:births", "bear:bears:bore:born:bearing", "born", null),
  "learn": new DFWord("learning:", "learn:learns:learned:learned:learning", "learned", null),
  "mischief": new DFWord("mischief:", null, "mischievous", null),
  "wisdom": new DFWord("wisdom:", null, "wise", null),
  "know": new DFWord("knower:knowers", "know:knows:knew:known:knowing", "knowing", null),
  "knowledge": new DFWord("knowledge:", null, null, null),
  "crack": new DFWord("crack:cracks", "crack:cracks:cracked:cracked:cracking", "cracked", null),
  "shatter": new DFWord(null, "shatter:shatters:shattered:shattered:shattering", "shattered", null),
  "leap": new DFWord("leap:leaps", "leap:leaps:leaped:leaped:leaping", null, null),
  "jump": new DFWord("jump:jumps", "jump:jumps:jumped:jumped:jumping", null, null),
  "dive": new DFWord("dive:dives", "dive:dives:dove:dived:diving", null, null),
  "vault_verb": new DFWord(null, "vault:vaults:vaulted:vaulted:vaulting", null, null),
  "vault_place": new DFWord("vault:vaults", null, null, null),
  "treasury": new DFWord("treasury:treasuries", null, null, null),
  "betray": new DFWord("betrayal:betrayals", "betray:betrays:betrayed:betrayed:betraying", null, null),
  "treason": new DFWord("treason:", null, null, null),
  "reason": new DFWord("reason:reasons", "reason:reasons:reasoned:reasoned:reasoning", null, null),
  "squat": new DFWord(null, "squat:squats:squatted:squatted:squatting", "squat", null),
  "festival": new DFWord("festival:festivals", null, "festive", null)
};